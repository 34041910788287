
$primary-color-shade-1: #0F1315;
$primary-color-shade-2: #252c38;
$primary-color-shade-3: #2f3744;
$primary-color-shade-4: #010912;
$primary-color-shade-5: #535d6e;
$primary-color-shade-6: #121720;
$primary-color-shade-7: #2c3441;
$primary-color-shade-8: #232a35;
$primary-color-shade-9: #1d232c;
$primary-color-shade-10: #191e27;
$primary-color-shade-11: #272e3a;

$secondary-color-shade-1: #15CE05;
$secondary-color-shade-2: #b13e00;

$tertiary-color-shade-0: #1E2531;
$tertiary-color-shade-1: #212529;
$tertiary-color-shade-2: #222;
$tertiary-color-shade-3: #333;
$tertiary-color-shade-8: #888;

$fourth-color-shade-1: #fff;
$fourth-color-shade-3: #f3f3f3;

$primary-color: #010912;
$secondary-color: #19674B;
$tertiary-color: #00e966;