@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|Oswald:200,300,400,500,600,700|Lora:400,700");

a,
a:hover,
a:focus,
button:focus {
	outline: 0 solid !important;
	text-decoration: none;
}

a {
	transition: 0.3s;
	color: $tertiary-color-shade-1;
	cursor: pointer;
}

a:hover {
	color: $tertiary-color;
}

img {
	max-width: 100%;
	height: auto;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0 0 15px;
	font-weight: 700;
}

html {
	// overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
	font-family: "ProximaNovaRegular", sans-serif;
	// overflow-x: hidden;
	position: relative;
	font-size: 14px;
	font-weight: 400;
	background: #124d38;
	scrollbar-width: thin;
	scrollbar-color: $primary-color-shade-3 #f1f1f1;
}

*,
.betslip-item-list {
	scrollbar-color: #165f44 #ffffff;
	scrollbar-width: thin;
}

::-webkit-scrollbar {
	width: 6px;
	height: 7px;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: #165f44;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #165f44;
}

/* HEADER AREA CSS START */
.header-area {
	background: $secondary-color;
	color: #fff;
	/* border-bottom: 3px solid #a61d67; */
	padding: 10px;
	padding-bottom: 14px;
	transition: 0.3s;
}

.header-bottom-border {
	position: absolute;
	width: 100%;
	height: 1px;
	background: transparentize($tertiary-color, 0.7%);
	left: 0;
	bottom: 0;
}

.header-area.fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2000;
}

.header-area [class*="col-lg-"],
.header-area [class*="col-md-"] {
	padding-left: 0;
	padding-right: 0;
}
.logo {
	display: flex;
	align-items: center;
	height: 100%;
}
.logo a {
	display: inline-flex;
	color: #fff;
	font-size: 27px;
	font-weight: 700;
}

.logo img {
	max-height: 45px;
}


.main-menu ul li {
	display: inline-block;
	position: relative;
}

.main-menu ul li a {
	color: $fourth-color-shade-1;
	text-transform: capitalize;
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	padding: 15px;
}

.main-menu ul li ul {
	position: absolute;
	left: 56%;
	top: 50px;
	transform: translateX(-50%);
	z-index: 1000;
	text-align: left;
	background: $fourth-color-shade-1;
	color: $tertiary-color-shade-3;
	border-radius: 0;
	width: auto;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.main-menu ul li ul li {
	display: flex;
	position: relative;
	flex-shrink: 0;
	white-space: nowrap;
}

.main-menu ul li ul li a {
	color: $primary-color-shade-3;
	padding: 7px 15px;
	border-bottom: 1px solid #ddd;
	display: flex;
	width: 100%;
}

.main-menu ul li ul li:last-child a {
	border: none;
}

.main-menu ul li a:hover,
.main-menu ul li.active a,
.main-menu ul li ul li.active a,
.main-menu ul li.active ul li:not(.active) a:hover {
	color: #face08;
}

.main-menu ul li.active ul li:not(.active) a {
	color: $primary-color-shade-3;
}

.main-menu ul li ul:before {
	position: absolute;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 10px solid #fff;
	border-bottom-color: $fourth-color-shade-1;
	content: "";
	left: 50%;
	top: -8px;
	transform: translateX(-50%);
}

.main-menu ul li:hover ul {
	opacity: 1;
	visibility: visible;
	top: 35px;
}



.main-menu ul li img {
	max-height: 5px;
}


.language-list .btn {
	background: transparent;
	border-radius: 0;
}

.language-list img {
	max-height: 16px;
}

.language-list .btn span {
	margin-left: 4px;
	display: inline-block;
}

.language-list .dropdown-toggle::after {
	display: none !important;
}

.language-list .btn.focus,
.language-list .btn:focus {
	outline: 0;
	box-shadow: none;
}

.language-list .dropdown-menu li {
	display: block;
	margin: 0;
	padding: 5px 10px 8px;
	transition: 0.3s;
}

.language-list .dropdown-menu li:last-child {
	padding-bottom: 8px;
	border-radius: 0;
}

.language-list .dropdown-menu li:hover {
	background: $primary-color-shade-4;
}

.language-list .dropdown-menu {
	min-width: initial;
	padding: 0;
	width: 70px;
	text-align: center;
}

.language-list .dropdown-menu li a {
	display: block;
}

.gift-box img {
	max-height: 24px;
}

/* HEADER AREA CSS END */
/* COMPANY LOGO AREA CSS START */
.logo-list ul li {
	display: inline-block;
	margin: 0 17px;
}

.logo-list ul li img {
	max-height: 20px;
}

.company-logo {
	padding: 15px 0;
	background: $primary-color-shade-1;
}

/* COMPANY LOGO AREA CSS END */
/* FOOTER AREA CSS START */
.footer-area {
	background: #19674bb3;
	padding: 20px 0;
}

.footer-links h3,
.mobile-apps h3,
.follow-us h3 {
	color: $fourth-color-shade-1;
	font-size: 13px;
	margin-bottom: 15px;
	text-transform: uppercase;
}

.footer-links ul li {
	position: relative;
	margin-bottom: 6px;
}

.footer-links ul li a {
	color: $fourth-color-shade-1;
	font-size: 13px;
	transition: 0.3s;
}

.footer-links ul li a:hover {
	color: $tertiary-color;
}

.follow-us ul li a {
	display: block;
	font-size: 20px;
	color: $fourth-color-shade-1;
	transition: 0.3s;
}

.follow-us ul li a:hover {
	color: #40a3ff;
	opacity: 1;
}

.mobile-apps ul li {
	margin-right: 10px;
	display: inline-block;
}

.mobile-apps ul li a {
	display: inline-block;
}

.mobile-apps ul li a:hover {
	opacity: 0.8;
}

.mobile-apps ul li img {
	height: 32px;
}

.follow-us ul li {
	display: inline-block;
	margin-right: 15px;
}

.follow-us ul li a:hover {
	opacity: 0.8;
}

.eighteen-plus h2,
.eighteen-plus .h2 {
	font-size: 18px;
	color: #00f10a;
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	margin-bottom: 0;
	border: 2px solid #00f10a;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	background: #00f10a17;
}

.eighteen-plus p {
	color: $fourth-color-shade-1;
	font-size: 13px;
	margin-bottom: 0;
}

.follow-us ul li img {
	max-height: 26px;
}

.mobile-apps h3,
.follow-us h3 {
	margin-bottom: 11px;
}

/* FOOTER AREA CSS END */
/* COPYRIGHT AREA CSS START */
.copyright-text {
	font-size: 13px;
	color: $fourth-color-shade-1;
	margin-left: 20px;
}

.copyright-logo img {
	max-height: 65px;
}

.copyright-area {
	padding: 20px 0 15px;
	background: #19674b;
}

.copyright-area p:last-child {
	margin-bottom: 0;
}

/* COPYRIGHT AREA CSS END */

.page-area {
	margin-top: 57px;
	min-height: calc(100vh - 414px);
}

/* LEFT SIDEBAR AREA CSS START */
.game-content-area,
.admin-template-area {
	padding: 5px;
	background: #124d38;
}

.left-sidebar,
.right-sidebar {
	padding: 0;
}

.left-sidebar-wrapper {
	display: flex;
	flex-direction: column;
	height: 100% !important;
	background: #fff;
	border-radius: 5px;
}

.game-content-area .row,
.admin-template-area .row {
	margin: 0;
}

.left-sidebar-toggle-btn-area {
	position: relative;
	background: $primary-color-shade-3;
	border-radius: 0;
	padding: 10px;
	cursor: pointer;
	height: 35px;
	color: #fff;
	font-size: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/* .left-sidebar-toggle-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  max-height: 12px;
} */
.left-sidebar-tab-search-wrapper {
	background: $primary-color-shade-3;
	margin-top: 8px;
	border-radius: 0;
	padding: 5px;
	margin-bottom: 8px;
	position: relative;
}

.left-sidebar-tab-links ul li {
	display: flex;
	background: $primary-color-shade-11;
}

.left-sidebar-tab-links ul li a {
	color: $fourth-color-shade-1 !important;
	padding: 3px 8px;
	display: block;
	cursor: pointer;
	min-width: 34px;
	text-align: center;
}

.left-sidebar-tab-links ul li i.zmdi {
	font-size: 18px;
}

.left-sidebar-tab-links ul li img {
	max-height: 10px;
}

.left-sidebar-tab-links ul li a.active {
	background: $primary-color-shade-4;
}

.left-sidebar-tab-links ul li:first-child,
.left-sidebar-tab-links ul li:first-child a {
	border-radius: 0;
}

.left-sidebar-tab-links ul li:last-child,
.left-sidebar-tab-links ul li:last-child a {
	border-radius: 0;
}

.left-sidebar-tab-links {
	margin-bottom: 5px;
}

.left-sidebar-search-area {
	position: relative;
	background: $fourth-color-shade-1;
	border-radius: 0;
}

.left-sidebar-search-area i.fas {
	position: absolute;
	top: 50%;
	font-size: 16px;
	transform: translateY(-50%);
}

.left-sidebar-search-area i.fas.fa-search {
	left: 8px;
}

.left-sidebar-search-area i.fas.fa-times {
	right: 8px;
	display: none;
}

.left-sidebar-search-area input[type="search"],
.left-sidebar-search-area input[type="text"] {
	width: 100%;
	border: none;
	padding: 5px;
	border-radius: 0;
	font-size: 13px;
	padding-left: 34px;
}

.favourite-card {
	color: $fourth-color-shade-1;
	background: $primary-color-shade-3;
	border-radius: 0;
	padding: 3px;
	cursor: pointer;
	margin-bottom: 8px;
	position: relative;
}

.favourite-card-header {
	padding: 10px;
}

.favourite-card-body {
	background: #f9f9f9;
	color: #717479;
	text-align: center;
	font-size: 13px;
	padding: 6px;
}

.favourite-card-header[aria-expanded="true"] i.fas.fa-chevron-down {
	display: none;
}

.favourite-card-header[aria-expanded="false"] i.fas.fa-chevron-up {
	display: none;
}

.favourite-card-header .number {
	margin-right: 6px;
	font-size: 88%;
}

.favourite-card-header i.fas.fa-star {
	margin-right: 6px;
	display: inline-block;
	font-size: 18px;
}

.sport-card-level-one {
	background: #fff;
	border-radius: 0;
	border-bottom: 1px solid #ddd;
}
.sport-card-level-one.active {
	background: #eee;
}

.sport-card-level-one-header img {
	max-height: 15px;
	margin-right: 6px;
}

.sport-card-level-one-header {
	padding: 6px 10px;
	cursor: pointer;
	border-radius: 0;
	transition: 0.3s;
	position: relative;
}

.sport-card-level-one-header[aria-expanded="true"] {
	border-radius: 0;
}

.sport-card-level-one-header .number {
	margin-right: 8px;
}

.sport-card-level-one-header[aria-expanded="true"] i.fas.fa-chevron-down {
	display: none;
}

.sport-card-level-one-header[aria-expanded="false"] i.fas.fa-chevron-up {
	display: none;
}

.sport-card-level-one-body {
	background: $fourth-color-shade-1;
	border: 3px solid $fourth-color-shade-1;
	padding: 2px 0;
}

.sport-card-level-two-header {
	background: $fourth-color-shade-1;
	padding: 4px 12px;
	cursor: pointer;
	font-size: 13px;
	overflow: hidden;
	padding-left: 25px;
}

.sport-card-level-two-header img {
	max-height: 15px;
	margin-right: 8px;
}

.sport-card-level-two-header .name {
	overflow: hidden;
	width: 80%;
	white-space: nowrap;
	margin-top: 3px;
	text-overflow: ellipsis;
}

.sport-card-level-two-header span {
	float: left;
}

.sport-card-level-two-header[aria-expanded="true"] i.fas.fa-chevron-down {
	display: none;
}

.sport-card-level-two-header[aria-expanded="false"] i.fas.fa-chevron-up {
	display: none;
}

.sport-card-level-two-header i.fas.fa-chevron-down,
.sport-card-level-two-header i.fas.fa-chevron-up {
	margin-top: 4px;
	font-size: 16px;
}

.sport-card-level-two-body {
	background: $fourth-color-shade-1;
	padding: 5px;
}

.sport-card-level-three {
	background: $fourth-color-shade-1;
	border-radius: 0;
}

.sport-card-level-three-header {
	font-size: 14px;
	padding: 5px;
	cursor: pointer;
	border-radius: 0;
	transition: 0.3s;
}

.sport-card-level-three-header .float-right {
	color: $secondary-color-shade-2;
}

.sport-card-level-three-header ul li:last-child .float-right {
	color: #aeb0b3;
}

.sport-card-level-three-header button {
	color: #aeb0b3;
	background: none;
	border: none;
	cursor: pointer;
}

.sport-card-level-three-header .status {
	color: $primary-color-shade-6;
	font-size: 12px;
}

.sport-card-level-three-header ul li:last-child {
	margin-top: 5px;
}

span.sport-card-level-three-accordion-btn[aria-expanded="true"]
	i.fas.fa-chevron-down,
span.sport-card-level-three-accordion-btn[aria-expanded="false"]
	i.fas.fa-chevron-up {
	display: none;
}

span.sport-card-level-three-accordion-btn {
	cursor: pointer;
}

.sport-card-level-three-body {
	text-align: center;
	font-size: 13px;
	border-top: 1px solid #c9c9c9;
}

.sport-card-level-three-body .row {
	margin: 0;
}

.sport-card-level-three-body .col {
	padding: 0;
}

.sport-card-level-three-body .row .col {
	border-right: 1px solid #c9c9c9;
}

.sport-card-level-three-body .row .col:last-child {
	border: none;
}

.single-bet-box {
	padding: 10px;
	position: relative;
	cursor: pointer;
	transition: 0.3s;
}

.single-bet-box .goal-number {
	margin-bottom: 6px;
}

.single-bet-box .goal-point {
	color: #014a85;
}

.single-bet-box.green .goal-point {
	color: #6fad4b;
}

.single-bet-box.red .goal-point {
	color: #dd563b;
}

.single-bet-box.green:before {
	position: absolute;
	right: 0;
	top: 0;
	border-top: 10px solid #6fad4b;
	content: "";
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
}

.single-bet-box.red:before {
	position: absolute;
	right: 0;
	bottom: 0;
	border-bottom: 10px solid #dd563b;
	content: "";
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
}

.sport-card-level-three-header:hover,
.single-bet-box:hover {
	background: #c9c9c9;
}

.sport-card-level-three-header:hover *,
.single-bet-box:hover * {
	color: #444 !important;
}

.sport-card-level-one .sport-card-level-one-header {
	border-color: $primary-color-shade-3;
}

.sport-card-level-one.green .sport-card-level-one-header {
	border-color: $primary-color-shade-6;
}

.sport-card-level-one.green .sport-card-level-one-header:hover,
.sport-card-level-one.green .sport-card-level-one-header[aria-expanded="true"],
.sport-card-level-one.green .sport-card-level-one-header.active {
	color: $fourth-color-shade-1;
	background: $primary-color-shade-6;
}

.sport-card-level-one.purple .sport-card-level-one-header {
	border-color: #473067;
}

.sport-card-level-one.purple .sport-card-level-one-header:hover,
.sport-card-level-one.purple .sport-card-level-one-header[aria-expanded="true"],
.sport-card-level-one.purple .sport-card-level-one-header.active {
	color: $fourth-color-shade-1;
	background: #473067;
}

.sport-card-level-one.megenta .sport-card-level-one-header {
	border-color: #a61f67;
}

.sport-card-level-one.megenta .sport-card-level-one-header:hover,
.sport-card-level-one.megenta
	.sport-card-level-one-header[aria-expanded="true"],
.sport-card-level-one.megenta .sport-card-level-one-header.active {
	color: #fff;
	background: #a61f67;
}

.sport-card-level-one.black .sport-card-level-one-header {
	border-color: #1b1b1b;
}

.sport-card-level-one.black .sport-card-level-one-header:hover,
.sport-card-level-one.black .sport-card-level-one-header[aria-expanded="true"],
.sport-card-level-one.black .sport-card-level-one-header.active {
	color: $fourth-color-shade-1;
	background: #1b1b1b;
}

.sport-card-level-one.orange .sport-card-level-one-header {
	border-color: #d96c23;
}

.sport-card-level-one.orange .sport-card-level-one-header:hover,
.sport-card-level-one.orange .sport-card-level-one-header[aria-expanded="true"],
.sport-card-level-one.orange .sport-card-level-one-header.active {
	color: $fourth-color-shade-1;
	background: #d96c23;
}

.sport-card-level-one.blue .sport-card-level-one-header {
	border-color: #1489ac;
}

.sport-card-level-one.blue .sport-card-level-one-header:hover,
.sport-card-level-one.blue .sport-card-level-one-header[aria-expanded="true"],
.sport-card-level-one.blue .sport-card-level-one-header.active {
	color: $fourth-color-shade-1;
	background: #1489ac;
}

.sport-card-level-one .sport-card-level-one-header,
.sport-card-level-two-header {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.sport-card-level-one-header span,
.sport-card-level-two-header span {
	display: flex;
	align-items: center;
}

.sport-card-level-one-header .float-right,
.sport-card-level-two-header .float-right {
	margin-left: auto;
}

.sport-card-level-one-header .name,
.sport-card-level-two-header .name {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* LEFT SIDEBAR AREA CSS END */
/* RIGHT SIDEBAR AREA CSS START */
.game-preview-card-header {
	background: $primary-color-shade-3;
	padding: 8px 12px;
	border-radius: 0;
	color: #c9c9c9;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.game-preview-card-header .football-field-icon img {
	width: 24px;
}

span.football-field-icon {
	display: inline-grid;
}

.game-preview-card-body {
	background: $primary-color-shade-3;
	border-top: 2px solid $primary-color-shade-1;
}

.game-preview-accordion-btn[aria-expanded="true"] i.fas.fa-chevron-down,
.game-preview-accordion-btn[aria-expanded="false"] i.fas.fa-chevron-up {
	display: none;
}

.game-preview-accordion-btn {
	cursor: pointer;
	margin-top: 5px;
}

.game-preview-accordion-btn i.fas {
	margin-left: 10px;
}

.sound-icon {
	cursor: pointer;
}

.sound-icon i.zmdi {
	font-size: 16px;
}

.sound-icon img,
.sound-icon i.zmdi {
	display: none;
}

.sound-icon img.active,
.sound-icon i.zmdi.active {
	display: inline-block;
}

.game-score-card span:not(.team-name) {
	display: flex;
}

.team-icon img {
	max-height: 9px;
}

.game-score-card {
	overflow: hidden;
	padding: 6px 10px;
	display: flex;
	justify-content: center;
}

.game-score-card .left-side,
.game-score-card .right-side {
	display: flex;
	width: 50%;
	align-items: center;
}

.team-tshirt img {
	max-height: 12px;
}

.team-name {
	max-width: 55%;
	width: auto;
	color: #c9c9c9;
	font-size: 13px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.team-score {
	background: #3f444e;
	color: #c9c9c9;
	font-weight: 700;
	font-size: 16px;
	padding: 4px 7px;
	border-radius: 0;
	min-width: 32px;
	text-align: center;
	justify-content: center;
}

.right-side .team-score {
	margin-right: auto;
}

.left-side .team-score {
	margin-left: auto;
}

.left-side {
	padding-right: 2px;
}

.right-side {
	padding-left: 2px;
}

.left-side .team-tshirt,
.left-side .team-name {
	margin-right: 5px;
}

.right-side .team-tshirt,
.right-side .team-name {
	margin-left: 5px;
}

.game-current-time {
	background: #007236;
	color: $fourth-color-shade-1;
	text-align: center;
	padding: 7px;
	font-size: 13px;
}

.game-play-preview-area {
	position: relative;
	padding: 3px;
}

.game-preview-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.game-field-area {
	position: relative;
	z-index: 2;
}

.game-status {
	background: rgba(0, 0, 0, 0.4);
	position: relative;
	z-index: 2;
	border-radius: 0;
	margin: 4px 3px 2px;
	padding: 2px 8px;
	color: $fourth-color-shade-1;
	font-size: 11px;
}

.game-status .left-side {
	font-weight: 700;
	text-transform: uppercase;
}

.game-status .right-side {
	position: relative;
	padding-right: 10px;
}

.game-status .right-side:before {
	position: absolute;
	right: 0;
	top: 2px;
	width: 2px;
	height: calc(100% - 4px);
	background: $fourth-color-shade-1;
	content: "";
}

.event-header {
	background: $fourth-color-shade-1;
	padding: 9px 12px;
	border-radius: 0;
	color: #202531;
	font-weight: 700;
}

.upcoming-events {
	margin-top: 8px;
}

.event-header a {
	color: #202531;
	font-weight: 400;
	transition: 0.3s;
}

.event-header a:hover {
	color: $primary-color-shade-4;
}

.event-type-list {
	background: $primary-color-shade-3;
	border-radius: 0;
	padding: 5px;
	overflow-x: auto;
	display: flex;
	width: 100%;
	position: relative;
}

.single-event-type-list-item {
	display: block;
	align-items: center;
	flex-shrink: 0;
	background: $fourth-color-shade-1;
	border-radius: 0;
	border-top: 3px solid $primary-color-shade-4;
	height: 35px;
	padding: 4px 8px;
	width: auto;
	white-space: nowrap;
	overflow: hidden;
	justify-content: flex-start;
	transition: background-color 0.3s, max-width 0.3s;
	max-width: 35px;
	cursor: pointer;
}

.single-event-type-list-item img {
	max-height: 20px;
	display: inline-block;
	height: 20px;
}

.single-event-type-list-item:not(last-child) {
	margin-right: 5px;
}

.single-event-type-list-item .name {
	margin-left: 8px;
}

.single-event-type-list-item.active,
.single-event-type-list-item:hover {
	max-width: 150px;
	background: $primary-color-shade-4;
	color: $fourth-color-shade-1;
}

.single-event-type-list-item.green {
	border-color: $primary-color-shade-6;
}

.single-event-type-list-item.green.active,
.single-event-type-list-item.green:hover {
	color: $fourth-color-shade-1;
	background: $primary-color-shade-6;
}

.single-event-type-list-item.purple {
	border-color: #473067;
}

.single-event-type-list-item.purple.active,
.single-event-type-list-item.purple:hover {
	color: $fourth-color-shade-1;
	background: #473067;
}

.single-event-type-list-item.megenta {
	border-color: #a61f67;
}

.single-event-type-list-item.megenta.active,
.single-event-type-list-item.megenta:hover {
	color: $fourth-color-shade-1;
	background: #a61f67;
}

.single-event-type-list-item.black {
	border-color: #1b1b1b;
}

.single-event-type-list-item.black.active,
.single-event-type-list-item.black:hover {
	color: $fourth-color-shade-1;
	background: #1b1b1b;
}

.single-event-type-list-item.orange {
	border-color: #d96c23;
}

.single-event-type-list-item.orange.active,
.single-event-type-list-item.orange:hover {
	color: $fourth-color-shade-1;
	background: #d96c23;
}

.single-event-type-list-item.blue {
	border-color: #1489ac;
}

.single-event-type-list-item.blue.active,
.single-event-type-list-item.blue:hover {
	color: $fourth-color-shade-1;
	background: #1489ac;
}

.event-time {
	display: flex;
	background: $fourth-color-shade-1;
	border-radius: 0;
	height: 34px;
	margin-top: 8px;
	margin-bottom: 8px;
}

.single-event-time {
	display: flex;
	max-width: 100%;
	flex-grow: 1;
	justify-content: center;
	cursor: pointer;
	height: 34px;
	align-items: center;
	color: $tertiary-color-shade-0;
}

.single-event-time:not(last-item) {
	border-right: 1px solid #ccc;
}

.single-event-time.active {
	background: $primary-color-shade-4;
	color: $fourth-color-shade-1;
}

.single-event-time.active:first-child {
	border-radius: 0;
}

.single-event-time.active:last-child,
.single-event-time:last-child {
	border-radius: 0;
}

.event-card-header {
	background: $fourth-color-shade-1;
	border-radius: 0;
	font-size: 10px;
	padding: 4px 8px;
	cursor: pointer;
	overflow: hidden;
}

.event-card-header[aria-expanded="true"] {
	border-radius: 0;
}

.event-card {
	margin-bottom: 5px;
}

.event-card-header span:not(.float-right) {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 93%;
	white-space: nowrap;
	float: left;
}

.event-card-header[aria-expanded="true"] i.fas.fa-chevron-down,
.event-card-header[aria-expanded="false"] i.fas.fa-chevron-up {
	display: none;
}

.event-card-body {
	background: #dfdfdf;
	padding: 5px;
}

.event-info {
	font-size: 13px;
	padding: 2px 7px;
	display: flex;
	flex-grow: 1;
	align-items: center;
	margin-bottom: 5px;
}

.event-info .time {
	font-weight: 700;
	color: #555;
}

.event-info span.details {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.event-info .count a {
	color: $tertiary-color-shade-1;
	border: 1px solid $tertiary-color-shade-1;
	border-radius: 0;
	font-size: 10px;
	display: flex;
	padding: 0 5px;
	transition: 0.3s;
}

.event-info .count a:hover {
	background: $tertiary-color-shade-1;
	color: $fourth-color-shade-1;
}

.event-info .count.red a {
	color: #a61d67;
	border-color: #a61d67;
}

.event-info .count.red a:hover {
	background: #a61d67;
	color: $fourth-color-shade-1;
}

.event-info .count.green a {
	color: $primary-color-shade-6;
	border-color: $primary-color-shade-6;
}
.event-info .count.green a:hover {
	background: $primary-color-shade-6;
	color: $fourth-color-shade-1;
}

.event-info .stat a {
	display: flex;
	color: #999;
	font-size: 14px;
	padding: 4px 8px;
	transition: 0.3s;
}

.event-info .stat a:hover {
	color: #444;
}

.event-info .stat {
	display: flex;
	flex-grow: 1;
}

.event-list ul {
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
}

.event-list ul li {
	display: flex;
	width: 100%;
	flex-shrink: 0;
	flex-grow: 1;
	flex-basis: 100%;
	background: $fourth-color-shade-1;
	padding: 5px 10px;
	border-radius: 0;
	transition: 0.3s;
	cursor: pointer;
}

.event-list ul li span:not(.list-name) {
	display: flex;
}

.event-list ul li span.coefficient {
	margin-left: auto;
	color: #014a85;
}

.event-list ul li:not(:last-child) {
	margin-bottom: 5px;
}

.event-list ul li:hover {
	background: #c9c9c9;
}

.event-list ul li span.list-name {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 90%;
	float: left;
}

.event-list ul li.active {
	background: $primary-color-shade-4;
	color: $fourth-color-shade-1;
}
.event-list ul li.active span.coefficient,
.event-list ul li.active:hover span.coefficient {
	color: $fourth-color-shade-1;
}
.event-list ul li:hover span.coefficient {
	color: $tertiary-color-shade-1;
}

#event-image-carousel {
	margin-top: 10px;
}

.single-statistics-box {
	position: relative;
	padding: 15px 5px;
}

.single-statistics-box .round-chart {
	position: relative;
	max-width: 55px;
	margin: 0 auto;
}

.round-chart-icon img {
	display: block;
}

.round-chart .left-value,
.round-chart .right-value {
	position: absolute;
	color: #c9c9c9;
	top: 50%;
	transform: translateY(-50%);
	font-size: 13px;
	font-weight: 600;
}

.round-chart .left-value {
	left: -5px;
}

.round-chart .right-value {
	right: -5px;
}

.round-chart-icon {
	display: block;
	max-width: 35px;
	margin: 0 auto;
}

.statistics-name {
	color: #c9c9c9;
	font-size: 10px;
	font-weight: 600;
	margin-top: 10px;
}

.game-summary-statistics .col {
	padding: 0 5px;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
	display: flex;
}

.owl-carousel .owl-item img {
	width: auto !important;
}

.cricket-match-summary-carousel.owl-carousel {
	background: transparent;
	overflow: hidden;
}

.cricket-match-summary-carousel .owl-nav button {
	position: absolute;
	width: 20px;
	height: 20px;
	left: 5px;
	top: 36px;
	background: #000 !important;
	color: #fff !important;
	opacity: 0.7;
	transition: 0.3s;
	line-height: 7px !important;
}

.cricket-match-summary-carousel .owl-nav button i.fas {
	font-size: 10px;
}

.cricket-match-summary-carousel .owl-nav button.owl-next {
	left: auto;
	right: 5px;
}

.cricket-match-summary-carousel:hover .owl-nav button:hover,
.cricket-match-summary-carousel:hover .owl-nav button.owl-next:hover {
	opacity: 0.9;
}

.cricket-match-summary {
	/* width: calc(100% - 8px); */
	display: flex;
	flex-direction: column;
}

.cricket-match-summary-header {
	display: flex;
	background: rgba(5, 58, 41, 0.85);
	color: $fourth-color-shade-1;
	font-size: 13px;
	height: 30px;
	align-items: center;
}

.green .cricket-match-summary-header {
	background: #00a651;
	color: $fourth-color-shade-1;
}

.black .cricket-match-summary-header {
	background: #252525;
	color: $fourth-color-shade-1;
}

.cricket-match-summary-header span {
	display: flex;
}

.cricket-match-summary-header span.match-type {
	background: $primary-color-shade-11;
	height: 30px;
	align-items: center;
	padding: 8px;
	text-transform: uppercase;
}

.green .cricket-match-summary-header span.match-type {
	background: #019047;
}

.black .cricket-match-summary-header span.match-type {
	background: #4b4b4b;
}

.cricket-match-summary-header span.match-date {
	margin-left: 7px;
}

.cricket-match-summary-header span.match-time {
	margin-left: auto;
	padding-right: 8px;
	font-weight: 700;
}

.cricket-match-summary-details {
	background: #e9e9e9;
	color: $fourth-color-shade-1;
}

.cricket-match-summary-details .match-result {
	font-size: 14px;
	text-align: center;
	padding: 20px 40px;
}

.cricket-match-summary-details .match-details-info {
	display: flex;
	justify-content: space-between;
	padding: 15px;
}

.cricket-match-summary-details .match-details-info span {
	display: flex;
}

.cricket-match-summary-details span.team-name-flag .flag img {
	height: 50px;
	border-radius: 0;
	max-width: 75px !important;
}

.cricket-match-summary-details span.team-name-flag {
	flex-direction: column;
	text-align: center;
	max-width: 75px;
}

.cricket-match-summary-details span.team-name-flag .flag {
	justify-content: center;
}

.cricket-match-summary-details span.team-name-flag .name {
	color: $fourth-color-shade-1;
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	font-size: 14px;
	margin-top: 8px;
	font-weight: 500;
	justify-content: center;
}

.cricket-match-summary-details .match-details-info .team-score {
	flex-direction: column;
	height: 50px;
	background: #e9e9e9;
	color: $fourth-color-shade-1;
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	font-size: 15px;
	justify-content: center;
	align-items: start;
	padding: 0;
	margin-left: 6px;
	line-height: 18px;
}

.cricket-match-summary-details .match-details-info .right-side .team-score {
	align-items: flex-end;
	margin-left: 0;
	margin-right: 6px;
}

.cricket-match-summary-details span.series-flag {
	align-items: center;
	width: 80px;
	padding: 0 20px;
	height: 50px;
}

.cricket-match-summary .match-location ul {
	display: flex;
	justify-content: center;
	text-align: center;
	flex-wrap: wrap;
	font-size: 12px;
	padding: 10px;
}

.cricket-match-summary .match-location ul li {
	display: inline-block;
	position: relative;
	margin: 0 15px;
}

.cricket-match-summary .match-location ul li:before {
	position: absolute;
	right: -16px;
	top: 50%;
	width: 5px;
	height: 5px;
	background: transparent;
	content: "";
	border-radius: 50%;
	transform: translateY(-50%);
}

.cricket-match-summary .match-location ul li:last-child:before {
	display: none;
}

.cricket-match-summary-details .match-details-info .team-score .bottom {
	font-size: 11px;
	color: $fourth-color-shade-1;
}

.cricket-match-summary-details .won span.team-name-flag .name,
.cricket-match-summary-details .match-details-info .won .team-score,
.cricket-match-summary-details .match-details-info .won .team-score .bottom {
	color: $fourth-color-shade-1;
}

/* football match summary css */
.football-match-summary-carousel.owl-carousel {
	background: #f4f4f4;
	overflow: hidden;
}

.football-match-summary-carousel .owl-nav button {
	position: absolute;
	width: 20px;
	height: 20px;
	left: 5px;
	top: 5px;
	background: $primary-color-shade-4 !important;
	color: $fourth-color-shade-1 !important;
	opacity: 0.7;
	transition: 0.3s;
	line-height: 7px !important;
}

.football-match-summary-carousel .owl-nav button i.fas {
	font-size: 10px;
}

.football-match-summary-carousel .owl-nav button.owl-next {
	left: auto;
	right: 5px;
}

.football-match-summary-carousel .owl-nav button:hover,
.football-match-summary-carousel .owl-nav button.owl-next:hover {
	opacity: 0.9;
}

.football-match-summary {
	background: #f4f4f4;
	display: flex;
	flex-direction: column;
	color: #666;
	padding: 20px 0;
	transition: 0.3s;
	width: calc(100% - 8px);
}

.football-match-summary:hover {
	color: #444;
}

.football-match-summary .match-date-time {
	text-align: center;
	padding: 10px;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 10px;
}

.football-match-summary .match-details-info {
	display: flex;
	justify-content: space-between;
	padding: 0 25px;
}

.football-match-summary .match-details-info span {
	display: flex;
}

.football-match-summary span.team-name-flag {
	flex-direction: column;
	text-align: center;
	max-width: 80px;
}

.football-match-summary span.team-name-flag .flag img {
	max-height: 60px;
}

.football-match-summary span.team-name-flag .flag {
	justify-content: center;
	margin-bottom: 10px;
}

.football-match-summary span.team-name-flag .name {
	font-family: "ProximaNovaCondensedRegular", sans-serif;
}

.football-match-summary .match-details-info .team-score {
	background: transparent;
	color: #666;
	padding: 0 30px;
	height: 60px;
	align-items: center;
}

.football-match-summary span.details-separator {
	height: 60px;
	font-size: 20px;
	font-weight: 700;
	align-items: center;
}

.football-match-summary .series-name {
	text-align: center;
	font-size: 13px;
	font-weight: 600;
	padding: 10px;
}

.football-match-summary .won *,
.football-match-summary .match-details-info .won .team-score {
	color: $tertiary-color-shade-0;
}

/* RIGHT SIDEBAR AREA CSS END */
/* GAME AREA CSS START */
.game-area,
.admin-content {
	padding: 0 2px;
	border-radius: 0;
}

.height-1000 {
	height: 1000px;
}

.game-content-area > .row,
.admin-template-area > .row {
	height: 100% !important;
	min-height: 1px;
}

.left-sidebar,
.right-sidebar,
.game-area,
.admin-content {
	position: relative;
	// overflow-x: hidden;
	// overflow-y: auto;
	// height: 100% !important;
	transition: 0.4s;
}

.left-sidebar {
	padding-right: 2px;
}

.right-sidebar {
	padding-left: 2px;
}

.live-in-play-header {
	color: #fff;
	text-align: center;
	font-size: 13px;
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	font-weight: 400;
	margin: 0;
	padding: 3px 10px 9px;
}

.live-in-play-header.in-mobile {
	display: none;
}

.live-in-play-carousel-parent-wrapper {
	background: #22815e;
	flex-wrap: nowrap;
	overflow: hidden;
	overflow-x: auto;
	border-radius: 5px 5px 0 0;
}
.live-in-play-carousel-item {
	display: flex;
	white-space: nowrap;
	padding: 12px;
	align-items: center;
	cursor: pointer;
	transition: 0.3s;
	position: relative;
}

.live-in-play-carousel-item:before {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 3px;
	background: #22815e;
	content: "";
	transition: 0.3s;
}

.live-in-play-carousel-item.active:before {
	background: #face08;
}

.simplebar-scrollbar::before {
	background: $fourth-color-shade-1;
}

.simplebar-track .simplebar-scrollbar.simplebar-visible::before {
	opacity: 0.9;
}

.drag-left-handle,
.drag-right-handle {
	position: absolute;
	top: 0;
	width: 20px;
	height: 100%;
	text-align: center;
	z-index: 3;
	font-size: 12px;
	line-height: 80px;
	color: $tertiary-color-shade-8;
	cursor: pointer;
	transition: 0.3s;
	background: #fff;
	opacity: 0;
	visibility: hidden;
}

.live-in-play-carousel {
	position: relative;
	width: 100%;
	height: 100%;
	flex-wrap: unset;
	flex-direction: row;
	border-bottom: none;
	left: 0;
	transition: 0.4s;
	background: $primary-color-shade-3;
}

.live-in-play-carousel.stage-padding {
	padding-left: 20px;
	padding-right: 20px;
}

.live-in-play-carousel.owl-carousel {
	display: flex !important;
}

.live-in-play-carousel-item img {
	width: 16px;
}

.live-in-play-carousel-item p {
	display: flex;
	color: #fff;
	font-size: 12px;
	transition: 0.3s;
	line-height: 15px;
}

.live-in-play-carousel-item.active p,
.live-in-play-carousel-item:hover p {
	color: #face08;
}

.single-match-result-accordion-header {
	font-size: 14px;
	border-radius: 0;
	transition: 0.3s;
	display: flex;
	align-items: center;
	padding-left: 5px;
	padding-right: 0;
}

.single-match-result-accordion-header .right-side {
	display: flex;
	margin-left: auto;
	align-items: center;
}

.single-match-result-accordion-header .right-side,
.single-match-result-accordion-header .right-side a {
	color: $fourth-color-shade-1;
	transition: 0.3s;
}

.single-match-result-accordion-header .favourite-action {
	color: #aeb0b3;
	background: none;
	border: none;
	cursor: pointer;
	transition: 0.3s;
	padding: 0 10px;
}

.single-match-result-accordion-header .status {
	color: #111 !important;
	font-size: 14px;
	font-weight: 600;
	display: flex;
	transition: 0.3s;
	text-align: left;
}

.single-match-result-accordion-header .status:hover {
	color: #000 !important;
}

.single-match-result-accordion-header[aria-expanded="true"]
	i.fas.fa-chevron-down,
.single-match-result-accordion-header[aria-expanded="false"]
	i.fas.fa-chevron-up {
	display: none;
}

.single-match-result {
	margin-top: 0;
	border-radius: 0;
	background: #d4e8e1;
	padding: 0;
}

.single-match-result-header {
	color: $fourth-color-shade-1;
	border-radius: 0;
	margin: 0;
	font-size: 15px;
	font-weight: 600;
	padding: 8px 10px;
	display: flex;
	align-items: start;
}

.single-match-result-header .left-side,
.single-match-result-header .right-side {
	display: flex;
}

.single-match-result-header .left-side .header-category-icon {
	max-width: 15px;
	margin-right: 6px;
	margin-top: 4px;
}

.single-match-result-header .left-side span.match-infos {
	flex-direction: column;
}

.single-match-result-header .left-side .header-live-icon {
	max-width: 18px;
	margin-left: 8px;
	margin-top: 7px;
}

.single-match-result-header .right-side {
	width: 30%;
	margin-left: auto;
	flex-direction: column;
}

.single-match-result-header .right-side * {
	justify-content: flex-end;
	display: flex;
	text-align: right;
	font-size: 13px;
}

.single-match-result-header .right-side p {
	margin-bottom: 2px;
}

.single-match-result-header .top-side img {
	max-width: 18px;
}

.single-match-result-header .top-side,
.single-match-result-header .top-side > * {
	display: flex;
	align-items: center;
}

.single-match-result-header span.top-side {
	align-items: flex-start;
}

.single-match-result-header .left-side span.match-infos {
	width: calc(100% - 20px);
}

.single-match-result-header .left-side .bottom-side {
	justify-content: flex-start;
}

.single-match-result-header .bottom-side {
	display: flex;
	margin-top: 3px;
	width: 100%;
	justify-content: flex-end;
}

.single-match-result-header .top-side .score-text {
	margin: 0 8px;
}

.single-match-result-header .current-time {
	font-size: 10px;
	color: #222;
}

.single-match-result-header .left-side {
	width: 70%;
	align-items: flex-start;
}

.single-match-result-header .left-side span {
	color: #222;
	transition: 0.3s;
}

.single-match-result-header .left-side:hover span {
	color: #000;
}

.single-match-result-header .left-side > * {
	display: flex;
}

.single-match-result-header span.top-side {
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	font-weight: 400;
}

.single-match-result-accordion-btn {
	width: 27px;
	height: 34px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px solid #bbb;
}

.single-match-result-accordion-header .right-side a:hover,
.single-match-result-accordion-header .favourite-action:hover,
.single-match-result-accordion-header .favourite-action.active {
	color: $secondary-color-shade-1;
}

.single-match-result-accordion-header .right-side a.bet-cash-out {
	color: $fourth-color-shade-1;
	color: #fff;
	font-size: 8px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	text-align: center;
	line-height: 14px;
	box-shadow: 0 0 0 1px #ddd, 0 0 0 2px #014a85;
	opacity: 0.7;
}

.single-match-result-accordion-header .right-side a.bet-cash-out:hover {
	opacity: 1;
}

.single-match-result-accordion-header .right-side a.match-event-link {
	margin-right: 12px;
}

.single-match-result-accordion-header .right-side a.match-event-link span {
	color: $fourth-color-shade-1;
}

.single-match-result-accordion-header .right-side a.match-event-link i.fas {
	font-size: 12px;
}

.single-match-result-accordion-header
	.right-side
	a.match-event-link
	span:hover {
	color: $secondary-color-shade-1;
}

.single-match-result-accordion-body {
	text-align: center;
	font-size: 13px;
}

/* .single-match-result-accordion-body .row .col {
    border-right: 1px solid #c9c9c9;
    border-top: 1px solid #c9c9c9;
} */
.single-match-result-accordion-body .row .col:last-child,
.single-match-result-accordion-body .row .col-lg-4:nth-child(3n + 3),
.single-match-result-accordion-body .row .col-lg-6:nth-child(2n),
.single-match-result-accordion-body .row .col-lg-3:nth-child(4n + 1) {
	border-right: none;
}

.single-match-result-box {
	padding: 7px 10px;
	position: relative;
	cursor: pointer;
	transition: 0.3s;
	background: #22815e;
	display: flex;
	align-items: center;
	text-align: left;
	z-index: 0;
	overflow: hidden;
	height: 33px;
	margin-top: 7px;
	justify-content: space-between;
}

.single-match-result-box .match-name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 90%;
	color: #fff;
}

.single-match-result-box .match-point {
	display: flex;
	margin-left: auto;
}

.single-match-result-box .match-point {
	color: #fff;
}

.single-match-result-box.green .match-point {
	color: $primary-color-shade-11;
}

.single-match-result-box.red .match-point {
	color: #dd563b;
}

.single-match-result-box.green:before {
	position: absolute;
	right: 0;
	top: 0;
	border-top: 10px solid $primary-color-shade-11;
	content: "";
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
}

.single-match-result-box.red:before {
	position: absolute;
	right: 0;
	bottom: 0;
	border-bottom: 10px solid #dd563b;
	content: "";
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
}

/* .single-match-result-box:hover {
  background: #c9c9c9;
} */
.single-match-result-box:hover * {
	color: #fff !important;
}

[data-balloon]:after,
[data-balloon]::before {
	background: rgba(17, 17, 17, 0.66);
}

[data-balloon][data-balloon-pos="left"]:hover:before,
[data-balloon][data-balloon-pos="left"][data-balloon-visible]:before {
	opacity: 0.72;
}

.single-match-result-box::after {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: "";
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgb(0 0 0 / 30%) 100%
	);
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgb(0 0 0 / 30%) 100%
	);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}

.single-match-result-box:hover::after,
.single-match-result-box.update::after {
	-webkit-animation: shine 0.75s;
	animation: shine 0.75s;
}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}

@keyframes shine {
	100% {
		left: 125%;
	}
}

.single-match-result-box.flip {
	transform-style: preserve-3d;
	-webkit-animation: box-flip 0.75s;
	animation: box-flip 0.75s;
}

@-webkit-keyframes box-flip {
	0% {
		transform: rotateX(-180deg);
	}

	100% {
		transform: rotateX(0deg);
	}
}

@keyframes box-flip {
	0% {
		transform: rotateX(-180deg);
	}

	100% {
		transform: rotateX(0deg);
	}
}

/* GAME AREA CSS END */
/* COLLAPS ACTION CSS START */
.left-sidebar.collaps-active {
	-ms-flex: 0 0 60px;
	flex: 0 0 60px;
	max-width: 60px;
}

.game-area.collaps-active {
	-ms-flex: 0 0 calc(75% - 70px);
	flex: 0 0 calc(75% - 70px);
	max-width: calc(75% - 70px);
}

.collaps-active .left-sidebar-toggle-btn-area {
	display: flex;
	justify-content: center;
	align-items: center;
}

.collaps-active .left-sidebar-toggle-btn {
	position: relative;
	right: auto;
	top: auto;
	transform: initial;
	/* max-height: 12px; */
}

.left-sidebar {
	z-index: 1000;
}

.game-area {
	z-index: 999;
	background: transparent;
}

/* AFTER NEW THEME */
.game-area {
	margin: 0 5px;
	flex: 0 0 calc(58.333333% - 15px);
	max-width: calc(58.333333% - 15px);
}

.left-sidebar,
.game-area,
.right-sidebar,
.admin-content {
	padding: 5px;
}

.game-indicator-message {
	font-size: 13px;
	text-align: center;
	margin-top: 20px;
	padding: 20px;
	color: $fourth-color-shade-1;
}

.collaps-active .favourite-card .name,
.collaps-active .favourite-card-body,
.collaps-active .favourite-card-header .accordion-icon,
.collaps-active .left-sidebar-tab-links,
.collaps-active .left-sidebar-search-area input[type="search"],
.collaps-active .left-sidebar-search-area input[type="text"],
.collaps-active .left-sidebar-search-area i.fas.fa-times,
.collaps-active .sport-card-level-one-header .name,
.collaps-active .sport-card-level-one-header .float-right,
.collaps-active .sport-card-level-one-body {
	display: none;
}

.collaps-active .favourite-card-header {
	height: 40px;
}

.collaps-active .left-sidebar-tab-search-wrapper,
.collaps-active .sport-card-level-one-header {
	height: 46px;
}

.collaps-active .left-sidebar-search-area i.fas.fa-search {
	color: $fourth-color-shade-1;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 18px;
}

.collaps-active .left-sidebar-search-area {
	background: transparent;
	height: 36px;
	cursor: pointer;
}

.collaps-active .sport-card-level-one-header img {
	margin-right: 0;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.collaps-active .sport-card-level-one-header[aria-expanded="true"] {
	border-radius: 0;
}

.sport-card-level-one .sport-card-level-one-header-link {
	display: flex;
	align-items: center;
	width: 100%;
	color: #111;
}

.sport-card-level-one
	.sport-card-level-one-header[aria-expanded="false"]
	.sport-card-level-one-header-link.active {
	color: #212529;
}

.sport-card-level-one-header[aria-expanded="true"]
	a.sport-card-level-one-header-link,
.sport-card-level-one:hover a.sport-card-level-one-header-link,
.sport-card-level-one.active .sport-card-level-one-header-link,
.sport-card-level-one .sport-card-level-one-header-link:hover,
.sport-card-level-one .sport-card-level-one-header-link.active,
.sport-card-level-one .sport-card-level-one-header:hover,
.sport-card-level-one
	.sport-card-level-one-header[aria-expanded="false"]:hover
	.sport-card-level-one-header-link.active {
	color: #000;
	font-weight: 600;
}

.game-summery-popup-area {
	padding: 0;
	background: #dfdfdf;
	color: $tertiary-color-shade-0;
	border-radius: 0;
	position: fixed;
	left: 75px;
	top: 0;
	z-index: 1200;
	width: 550px;
	border: 1px solid $primary-color-shade-3;
	font-size: 13px;
	display: none;
}

.game-summery-popup-area.active {
	display: block;
}

.game-summery-popup-header {
	background: $primary-color-shade-3;
	color: $fourth-color-shade-1;
	position: relative;
	font-size: 13px;
	font-weight: 400;
	height: 40px;
	display: flex;
	padding: 15px;
	align-items: center;
	margin: 0;
}

.game-summery-popup-header:before {
	position: absolute;
	left: -10px;
	top: 50%;
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-right: 15px solid $primary-color-shade-3;
	border-bottom: 10px solid transparent;
	content: "";
	transform: translateY(-50%);
}

.game-summery-popup-body .favourite-text {
	margin: 0;
	padding: 15px;
	text-align: center;
	color: $tertiary-color-shade-3;
	border-radius: 0;
}

.game-summery-popup-body .favourite-text i.fas {
	color: $tertiary-color-shade-8;
}

.game-summery-popup-body .search-collapsed-area {
	background: $fourth-color-shade-1;
	position: relative;
	border-radius: 0;
}

.game-summery-popup-body .search-collapsed-area input[type="search"],
.game-summery-popup-body .search-collapsed-area input[type="text"] {
	background: $fourth-color-shade-1;
	border: none;
	width: 100%;
	height: 40px;
	padding: 10px;
}

.game-summery-popup-body .search-collapsed-area i.fas.fa-times {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	display: none;
}

.game-summery-popup-body .search-collapsed-area input[type="search"]:focus,
.game-summery-popup-body .search-collapsed-area input[type="text"]:focus,
.game-summery-popup-body .search-collapsed-area:focus {
	outline: 0 solid;
}

.game-list {
	background: $fourth-color-shade-1;
	border-radius: 0;
}

.game-list ul li {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid #ccc;
	padding: 8px;
	cursor: pointer;
	transition: 0.3s;
}

.game-list ul li span {
	display: flex;
	align-items: center;
}

.game-list span.match-collapsed-title {
	width: 70%;
}

.game-list span.team-collaped-name {
	max-width: 49%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: inline-block !important;
}

.game-list span.team-divider {
	padding: 0 5px;
}

.game-list ul li:hover {
	background: $fourth-color-shade-3;
}

.game-list span.video-icon img {
	max-width: 16px;
}

.game-list span.match-collapsed-score {
	color: $primary-color-shade-6;
}

.game-list span.score-divider {
	padding: 0 3px;
}

.game-list .match-collapsed-details .favourite-action {
	background: none;
	border: none;
	padding: 0;
	margin-left: 10px;
	color: #999;
	transition: 0.3s;
}

.game-list .match-collapsed-details .favourite-action:hover {
	color: $tertiary-color-shade-3;
}

/* COLLAPS ACTION CSS END */
/* BETSLIP AREA CSS START */
.betslip-card.fixed {
	margin: 0;
	position: fixed;
	bottom: 0;
	right: 50px;
	z-index: 2000;
}

.betslip-card {
	width: 365px;
	background: #22815e;
}

.betslip-card-header {
	background: #22815e;
	padding: 7px 10px;
	color: $fourth-color-shade-1;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

span.betslip-card-header-text {
	position: relative;
	font-size: 14px;
}

.betslip-card-header-text .number {
	background: $secondary-color-shade-1;
	color: $tertiary-color-shade-1;
	width: 16px;
	position: absolute;
	right: -18px;
	top: -5px;
	text-align: center;
	border-radius: 50%;
	font-size: 10px;
	height: 16px;
	line-height: 16px;
}

.betslip-card-header[aria-expanded="true"] i.fas.fa-chevron-up,
.betslip-card-header[aria-expanded="false"] i.fas.fa-chevron-down {
	display: none;
}

.betslip-currency-type .form-control {
	font-size: 13px;
	background: transparent;
	padding: 10px 5px;
	border-radius: 0;
}

.betslip-currency-type .form-control:focus {
	border: none;
	outline: 0 solid;
	box-shadow: 0 0 0 0;
}

.betslip-actions {
	background: #dd1818;

	padding: 9px 5px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	margin-bottom: 5px;
}

.betslip-action-list .form-control {
	font-size: 12px;
	padding: 0;
	height: auto;
	background: #dfdfdf;
}

.betslip-action-list .form-control:focus {
	outline: 0 solid;
	box-shadow: 0 0 0 0;
	border: none;
}

.betslip-item-remove {
	font-size: 12px;
	cursor: pointer;
}

.betslip-item-list {
	max-height: 300px;
	/* overflow-y: auto; */
	/* margin-bottom: 5px; */
}

.betslip-actions.betslip-message {
	flex-direction: column;
}

.betslip-actions.betslip-message h5 {
	margin: 0;
	font-size: 14px;
}

.betslip-actions.betslip-message h5.bet-error-message {
	margin-top: 10px;
	font-size: 12px;
	color: #c3d002;
}

.single-betlist-item .top-part span {
	display: flex;
}

span.red-mark-ban-icon {
	position: relative;
	width: 15px;
	height: 15px;
	cursor: pointer;
	margin-right: 10px;
}

span.red-icon-tooltip {
	position: absolute;
	border: 1px solid #e44a3f;
	border-radius: 0;
	background: #f5f5f5;
	flex-direction: column;
	width: 220px;
	font-size: 13px;
	bottom: 22px;
	left: -12px;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.red-mark-ban-icon:before {
	position: absolute;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 6px solid #e44a3f;
	content: "";
	top: -8px;
	left: 46%;
	transform: translateX(-50%);
	transition: 0.3s;
	opacity: 0;
	visibility: hidden;
}

.single-betlist-item:first-child .red-mark-ban-icon:before {
	border-top: 6px solid transparent;
	border-bottom: 6px solid #e44a3f;
	top: auto;
	bottom: -8px;
}

.single-betlist-item:first-child span.red-icon-tooltip {
	bottom: auto;
	top: 22px;
}

span.red-icon-tooltip .tooltip-top-part {
	padding: 2px 10px;
	background: #e44a3f;
	color: #fff;
}

span.red-icon-tooltip .tooltip-bottom-part {
	padding: 5px 10px;
}

.single-betlist-item .top-part {
	display: flex;
	align-items: center;
	padding: 5px 1px;
	justify-content: space-between;
}

span.red-mark-ban-icon:hover span.red-icon-tooltip,
span.red-mark-ban-icon:hover:before {
	opacity: 1;
	visibility: visible;
}

span.betlist-info-icon {
	position: relative;
	width: 15px;
	height: 15px;
	cursor: pointer;
	margin-left: 10px;
}

span.info-icon-tooltip {
	position: absolute;
	right: 30px;
	background: #dfdfdf;
	border-radius: 0;
	box-shadow: 0px 1px 2px #aaa;
	width: 230px;
	flex-direction: column;
	border: 1px solid #ccc;
	top: -8px;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

span.info-icon-tooltip .tooltip-top-part img {
	max-height: 16px;
	margin-right: 9px;
	display: flex;
}

span.info-icon-tooltip .tooltip-top-part {
	background: $primary-color-shade-6;
	border-radius: 0;
	color: $fourth-color-shade-1;
	font-size: 13px;
	padding: 5px 10px;
	line-height: 15px;
	position: relative;
	align-items: center;
	height: 32px;
}

span.info-icon-tooltip .tooltip-top-part:before {
	position: absolute;
	right: -8px;
	top: 48%;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-left: 8px solid $primary-color-shade-6;
	content: "";
}

.green span.info-icon-tooltip .tooltip-top-part {
	background: $primary-color-shade-6;
}

.green span.info-icon-tooltip .tooltip-top-part:before {
	border-left: 8px solid $primary-color-shade-6;
}

.purple span.info-icon-tooltip .tooltip-top-part {
	background: #473067;
}

.purple span.info-icon-tooltip .tooltip-top-part:before {
	border-left: 8px solid #473067;
}

.megenta span.info-icon-tooltip .tooltip-top-part {
	background: #a61f67;
}

.megenta span.info-icon-tooltip .tooltip-top-part:before {
	border-left: 8px solid #a61f67;
}

.black span.info-icon-tooltip .tooltip-top-part {
	background: #1b1b1b;
}

.black span.info-icon-tooltip .tooltip-top-part:before {
	border-left: 8px solid #1b1b1b;
}

.orange span.info-icon-tooltip .tooltip-top-part {
	background: #d96c23;
}

.orange span.info-icon-tooltip .tooltip-top-part:before {
	border-left: 8px solid #d96c23;
}

.blue span.info-icon-tooltip .tooltip-top-part {
	background: #1489ac;
}

.blue span.info-icon-tooltip .tooltip-top-part:before {
	border-left: 8px solid #1489ac;
}

span.info-icon-tooltip .tooltip-bottom-part {
	padding: 4px 10px;
	font-size: 13px;
}

span.betlist-info-icon:hover span.info-icon-tooltip {
	opacity: 1;
	visibility: visible;
}

span.betlist-info-icon i.fas,
.red-mark-ban-icon i.fas,
.betlist-single-item-remove-icon i.fas {
	color: #666;
	transition: 0.3s;
}

span.betlist-info-icon i.fas:hover,
.red-mark-ban-icon i.fas:hover {
	color: #333;
}

.betlist-single-item-remove-icon i.fas:hover {
	color: #e44a3f;
}

span.betlist-single-item-remove-icon {
	cursor: pointer;
	margin-left: 10px;
	width: 15px;
	height: 15px;
}

span.betlist-text {
	font-size: 12px;
	width: calc(100% - 20px);
	flex-direction: column;
	align-items: center;
}

.single-betlist-item .top-part span.match-vs {
	font-weight: 700;
	text-transform: uppercase;
	font-size: 11px;
}

.single-betlist-item .middle-part span.bet-name {
	font-size: 11px;
	font-weight: 700;
	margin-bottom: 4px;
	display: flex;
}

.single-betlist-item .bottom-part {
	background: #f5f5f5;
	border-radius: 0;
	border: 1px solid #ccc;
	padding: 5px 0px;
	font-size: 12px;
}

.single-result-item {
	display: flex;
	justify-content: space-between;
	padding: 1px 10px;
	align-items: center;
}

.single-result-item .stroke-through {
	text-decoration: line-through;
}

.single-betlist-item .bottom-part span.non-stroke-through,
.single-betlist-item .bottom-part span.stroke-through {
	margin-right: 7px;
	background: $primary-color-shade-3;
	color: #fff;
	padding: 2px 10px;
	display: inline-block;
	border-radius: 0;
	text-align: center;
	min-width: 35px;
	font-size: 10px;
	/* font-weight: 700; */
}

.single-result-item .blue {
	color: #014a85;
}

.single-result-item .down-icon {
	color: $secondary-color-shade-1;
}

.single-result-item .up-icon {
	color: $primary-color-shade-6;
}

.betslip-bid-area ul {
	display: flex;
	flex-direction: column;
}

.betslip-bid-area ul li {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 12px;
	padding: 4px 6px;
}

.betslip-bid-area ul li input[type="text"]:focus,
.betslip-bid-area ul li input[type="number"]:focus {
	outline: 0 solid;
}

.betslip-bid-area ul li input[type="text"],
.betslip-bid-area ul li input[type="number"] {
	width: 80px;
	text-align: right;
	border: none;
	background: #fff;
	color: #111;
	padding: 6px;
	border-radius: 0;
	height: 24px;
}

.betslip-bid-area {
	padding: 5px;
	border-radius: 0;
	color: #111;
	margin-bottom: 5px;
}

.betslip-bid-area ul li .blue {
	color: #000;
}

.betslip-bid-area ul li:last-child {
	border-top: 1px solid #b5b5b5;
	margin-top: 4px;
	padding-top: 6px;
}
.betslip-bid-area ul li .right-side {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: flex-end;
}
.release-bonus-link {
	color: #ddd;
}
.place-bet-btn,
.accept-changes-btn {
	background: #face08;
	border-radius: 5px;
	width: 100%;
	color: #19674b;
	position: relative;
	padding: 8px;
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	font-weight: 700;
	letter-spacing: 0.7px;
	border: none;
}

.accept-changes-btn {
	transition: 0.3s;
	cursor: pointer;
}

.accept-changes-btn.inactive {
	background: $primary-color-shade-5;
	cursor: default;
}

.accept-changes-btn.inactive:hover {
	background: $primary-color-shade-4;
}

span.button-tooltip {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 51%;
	text-align: center;
	font-size: 10px;
	font-family: "ProximaNovaRegular", sans-serif;
	top: -30px;
	background: #999a9c;
	border-radius: 6px;
	color: #ddd;
	padding: 3px 8px;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

span.button-tooltip a {
	color: #ddd;
	text-decoration: underline;
}

span.button-tooltip:before {
	position: absolute;
	left: 50%;
	bottom: -6px;
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-top: 6px solid #999a9c;
	content: "";
	transform: translateX(-50%);
}

button.place-bet-btn:hover span.button-tooltip {
	opacity: 1;
	visibility: visible;
}

/* BETSLIP AREA CSS END */
/* REGISTRATION SIGN IN AREA CSS START */
/****  floating-Lable style start ****/
.floating-label {
	position: relative;
	margin-bottom: 20px;
}

.floating-input,
.floating-textarea,
.floating-select {
	font-size: 14px;
	padding: 6px 10px;
	display: block;
	width: 100%;
	height: 40px;
	background-color: $fourth-color-shade-1;
	border: 1px solid $fourth-color-shade-1;
	border-radius: 0;
	padding-top: 20px;
}

.form-control.empty {
	border: 1px solid #ff4545;
}

.floating-select {
	padding: 6px 6px;
	padding-top: 20px;
	font-size: 12px;
}

.floating-input:focus,
.floating-label.control-focus .floating-input:focus,
.floating-textarea:focus,
.floating-label.control-focus .floating-textarea:focus,
.floating-select:focus,
.floating-label.control-focus .floating-select:focus {
	outline: none;
	box-shadow: 0 0 0 0;
	font-size: 14px;
}

.floating-label.control-focus .floating-select:focus {
	font-size: 12px;
}

.floating-label label {
	color: #555;
	font-size: 14px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	left: 10px;
	top: 50%;
	transition: 0.2s ease all;
	-moz-transition: 0.2s ease all;
	-webkit-transition: 0.2s ease all;
	transform: translateY(-50%);
	z-index: 1510;
}

.floating-label .floating-textarea ~ label {
	top: 30px;
}

.floating-input:focus ~ label,
.floating-input:not(:placeholder-shown) ~ label,
.floating-textarea:focus ~ label,
.floating-textarea:not(:placeholder-shown) ~ label,
.floating-select:focus ~ label,
.floating-select:not([value=""]):valid ~ label,
.floating-label .floating-select-label {
	top: 11px;
	font-size: 10px;
	transform: translateY(-50%);
}

.floating-label.control-focus label,
.floating-label.control-highlight label {
	top: 11px;
	font-size: 10px;
	transform: translateY(-50%);
}

.floating-textarea {
	min-height: 100px;
	max-height: 260px;
}

/****  floating-Lable style end ****/
/* section.registration-sign-in-area {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2500;
    top: 0;
    left: 0;
} */
.registration-sign-in-area-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000;
	z-index: 3500;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
	transition-delay: 0.2s;
}

.registration-sign-in-area-overlay.active {
	opacity: 0.7;
	visibility: visible;
}

.sign-in-area,
.forgot-password-area {
	position: fixed;
	left: 50%;
	top: 51%;
	width: 450px;
	transform: translate(-50%, -50%);
	background: #efefef;
	border-radius: 0;
	/* max-height: 500px; */
	transition: 0.3s;
	transition-delay: 0.15s;
	opacity: 0;
	visibility: hidden;
	z-index: 3501;
}

.sign-in-area.active,
.forgot-password-area.active {
	top: 50%;
	opacity: 1;
	visibility: visible;
}

.rs-header {
	background: $primary-color-shade-3;
	padding: 18px;
	text-align: center;
	color: $fourth-color-shade-1;
	text-transform: uppercase;
	font-size: 17px;
	border-radius: 0;
	position: relative;
}

.rs-header .close {
	position: absolute;
	right: 17px;
	top: 50%;
	transform: translateY(-50%);
	color: $fourth-color-shade-1;
	height: 20px;
	width: 20px;
	display: flex;
	transition: 0.3s;
}

.rs-header .close.chevron {
	right: auto;
	left: 17px;
	display: flex;
}

.rs-header .close.chevron.inactive {
	display: none;
}

.rs-header .close img {
	max-height: 20px;
	display: flex;
}

.rs-body {
	padding: 30px;
}

.rs-submit {
	text-align: center;
}

.rs-submit input[type="submit"],
.rs-submit button {
	background: $primary-color;
	text-transform: uppercase;
	border: none;
	color: $fourth-color-shade-1;
	padding: 10px 60px;
	border-radius: 0;
	font-size: 16px;
	line-height: 16px;
	transition: 0.3s;
	cursor: pointer;
	width: 100%;
	margin-top: 15px;
}

.rs-submit input[type="submit"]:hover,
.rs-submit button:hover {
	background: $primary-color-shade-3;
}

.rs-submit input[type="submit"]:focus,
.rs-submit button:focus {
	outline: 0 solid;
	box-shadow: 0 0 0 0;
}

.rs-other-info {
	display: flex;
	justify-content: center;
	font-size: 12px;
	margin-top: 40px;
	align-items: center;
	text-align: center;
	flex-direction: column;
}

.rs-other-info .text-center {
	display: flex;
	margin: auto;
	align-items: center;
}

.rs-other-info .rs-link {
	cursor: pointer;
	margin-left: 10px;
	color: $tertiary-color;
	font-weight: 700;
	transition: 0.3s;
	text-decoration: underline;
}

.rs-other-info .rs-sign-in-link:hover {
	color: $tertiary-color;
}

.field-icon {
	position: absolute;
	right: 10px;
	top: 50%;
	z-index: 2;
	transform: translateY(-50%);
	cursor: pointer;
	transition: 0.3s;
	color: #222;
}

.fp-instruction-text {
	font-size: 17px;
	margin-bottom: 25px;
}

/* REGISTRATION SIGN IN AREA CSS END */
/* Sign IN CSS START */


button.btn.btn-primary.sign-in-btn {
	color: #111;
	background: $secondary-color-shade-1;
	border: none;
	width: 100%;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 600;
	margin-top: 15px;
	justify-content: center;
	border-radius: 0;
}

button.btn.btn-primary.sign-in-btn:hover {
	background: $secondary-color-shade-2;
}



.select2-container {
	z-index: 4502;
	width: 100% !important;
}

.select2-dropdown {
	border: 1px solid #ddd !important;
}

.select2-container--default .select2-selection--single {
	border: none !important;
}

.select2-container--default .select2-selection--single:focus {
	border: none !important;
	outline: 0 solid !important;
	box-shadow: 0 0 0 0 !important;
}

.select2-container--default
	.select2-selection--single
	.select2-selection__rendered {
	color: #555 !important;
	font-size: 14px !important;
	line-height: 16px !important;
}

.select2-container .select2-selection--single {
	height: 40px !important;
	border: none !important;
}

.select2-container--default
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: #666 transparent transparent transparent !important;
	border-width: 7px 7px 0 7px !important;
	margin-left: -7px !important;
}

.select2-container--default.select2-container--open
	.select2-selection--single
	.select2-selection__arrow
	b {
	border-color: transparent transparent #666 transparent !important;
	border-width: 0 7px 7px 7px !important;
}

.select2-container--default
	.select2-selection--single
	.select2-selection__arrow {
	height: 100% !important;
	top: 0 !important;
	right: 8px !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 10px !important;
	padding-right: 28px !important;
	padding-top: 20px !important;
}

.row.form-inputs-wrapper .col-lg-6:nth-child(2n) {
	padding-left: 10px;
}

.row.form-inputs-wrapper .col-lg-6:nth-child(2n + 1) {
	padding-right: 10px;
}

.rs-other-info.termes-policy {
	margin: 0;
}

.rs-other-info.termes-policy span {
	white-space: pre-line;
	word-wrap: break-word;
	display: block;
}

.rs-other-info.termes-policy a {
	color: #face08;
	font-weight: 700;
	margin-left: 6px;
	transition: 0.3s;
}

.rs-other-info.termes-policy a:hover {
	color: #face08;
}

/* SIGN IN CSS END */
/* LIVE SUPPORT CSS START */
.live-support-content-area {
	/* height: 500px; */
	width: 360px;
	position: fixed;
	z-index: 3000;
	left: 20px;
	bottom: 70px;
	border-radius: 0;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
}

.live-support-content-area.active {
	bottom: 90px;
	opacity: 1;
	visibility: visible;
}

.live-support-content-area.inactive {
	display: none;
	opacity: 0;
	visibility: hidden;
}

.live-support-icon {
	position: fixed;
	bottom: 20px;
	left: 20px;
	color: $primary-color-shade-1;
	height: 50px;
	align-items: center;
	z-index: 3000;
	cursor: pointer;
}

.live-support-icon .icon {
	position: relative;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.3s;
	background: $secondary-color-shade-1;
	height: 50px;
	z-index: 1;
	font-size: 25px;
}

.live-support-icon .icon:before {
	position: absolute;
	right: -7px;
	top: 50%;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 8px solid $secondary-color-shade-1;
	content: "";
	transition: 0.3s;
}

.live-support-icon .text {
	transition: 0.3s;
	display: flex;
	padding: 0 20px;
	position: absolute;
	background: $secondary-color-shade-1;
	left: 0;
	top: 0;
	height: 50px;
	white-space: nowrap;
	align-items: center;
	opacity: 0;
	visibility: hidden;
	font-weight: 600;
	font-size: 18px;
}

.live-support-icon:hover .icon {
	background: $primary-color-shade-1;
	color: $secondary-color-shade-1;
}

.live-support-icon:hover .icon:before {
	border-left-color: $primary-color-shade-1;
}

.live-support-icon:hover .text {
	opacity: 1;
	visibility: visible;
	left: 50px;
}

.live-support-icon.active .icon:before {
	display: none;
}

.live-support-icon.active .text {
	display: none;
}

span.live-support-content-close img {
	max-height: 10px;
}

span.live-support-content-close {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 3500;
	width: 10px;
	height: 10px;
	display: flex;
	cursor: pointer;
	transition: 0.3s;
}

span.live-support-content-close:hover {
	opacity: 0.6;
}

.live-support-content-header {
	height: 45px;
	color: #fff;
	padding: 10px;
	border-radius: 0;
	font-weight: 700;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.live-support-send-btn {
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
}

.live-support-send-btn button {
	display: flex;
	width: 80px;
	height: 80px;
	justify-content: center;
	align-items: center;
	background: $tertiary-color-shade-3;
	border: none;
	font-size: 28px;
	border-radius: 50%;
	padding: 0;
	cursor: pointer;
	padding-left: 5px;
	transition: 0.3s;
	position: relative;
	z-index: 1;
}

.live-support-send-btn button:hover {
	background: $tertiary-color-shade-3;
	color: $fourth-color-shade-1;
}

.live-support-send-btn button:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $fourth-color-shade-1;
	content: "";
	border-radius: 50%;
	z-index: -1;
	transform: scale(1);
	transition: 0.3s;
}

.live-support-send-btn button:hover:before {
	transform: scale(0);
}

.admin-body .live-support-icon {
	left: auto;
	right: 25px;
}

.admin-body .live-support-icon .icon:before {
	right: auto;
	left: -7px;
	border-left: transparent;
	border-right: 8px solid $secondary-color-shade-1;
}

.admin-body .live-support-icon .text {
	left: auto;
	right: 0;
}

.admin-body .live-support-icon:hover .text {
	left: auto;
	right: 50px;
}

.admin-body .live-support-content-area {
	left: auto;
	right: 25px;
}

/* LIVE SUPPORT CSS END */

/* GAME VIEW BUTTON LIST CSS START */
.game-view-btn-list-area ul {
	display: flex;
	justify-content: space-around;
	align-items: center;
	border-radius: 0;
	margin-bottom: 5px;
	background: #eee;
}

.game-view-btn-list-area ul li {
	font-size: 13px;
	color: $tertiary-color-shade-0;
	width: 50%;
	text-align: center;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #bbb;
	transition: 0.3s;
	cursor: pointer;
	border-top: 3px solid #eee;
}

.game-view-btn-list-area ul li a {
	color: $tertiary-color-shade-0;
	transition: 0.3s;
	display: flex;
	flex-basis: 100%;
	justify-content: center;
	height: 100%;
	align-items: center;
}

.game-view-btn-list-area ul li:last-child {
	border-right: none;
}

.game-view-btn-list-area ul li:hover {
	background: $fourth-color-shade-1;
}

.game-view-btn-list-area ul li.active {
	border-top-color: $primary-color-shade-4;
	background: $fourth-color-shade-1;
}

/* GAME VIEW BUTTON LIST AREA CSS END */
/* GAME BANNER SUMMARY AREA CSS START */
.single-game-banner {
	position: relative;
	background-size: cover;
	background-position: center;
	height: 230px;
}

.game-banner-match-summary {
	position: absolute;
	width: 60%;
	height: auto;
	background: $fourth-color-shade-1;
	z-index: 2500;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	border-radius: 0;
	box-shadow: 0 40px 30px -23px rgba(0, 0, 0, 0.75);
}

.game-banner-match-summary-inner {
	display: flex;
	position: relative;
	z-index: 1;
}

.game-banner-match-summary-inner .teams {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.game-banner-match-summary-inner .game-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
}

.game-banner-match-summary-inner .game-info span {
	padding: 7px 10px;
}

.game-banner-match-summary-inner .teams div {
	display: flex;
	align-items: center;
	padding: 7px 0;
	padding-left: 10px;
}

.game-banner-match-summary-inner .teams div:first-child {
	color: $fourth-color-shade-1;
	border-top-left-radius: 5px;
	font-size: 13px;
}

.game-banner-match-summary-inner .game-info span:first-child {
	color: $fourth-color-shade-1;
	font-size: 13px;
}

.game-banner-match-summary-inner:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 32px;
	background: $primary-color-shade-3;
	content: "";
	z-index: -1;
	border-radius: 0;
}

.game-banner-match-summary-inner:after {
	position: absolute;
	left: 10px;
	bottom: 35px;
	width: calc(100% - 20px);
	height: 1px;
	background: #ddd;
	content: "";
}

.game-banner-match-summary-inner .teams div.non-colorable img {
	margin-right: 10px;
	display: flex;
}

.game-banner-match-summary-inner .teams div.non-colorable span {
	line-height: 23px;
	display: flex;
}

/* GAME BANNER SUMMARY AREA CSS END */
/* MATCH DETAILS GAME HEADER AREA CSS START */
.match-details-game-box {
	overflow: hidden;
	border-radius: 0;
	padding: 0;
	background: #d4e8e1;
}
.match-details-game-header {
	color: #111;
	display: flex;
	justify-content: space-between;
	padding: 5px;
	align-items: center;
}

.match-details-game-header.green {
	background: $primary-color-shade-6;
}

.match-details-game-header.purple {
	background: #473067;
}

.match-details-game-header.black {
	background: #1b1b1b;
}

.match-details-game-header.orange {
	background: #d96c23;
}

.match-details-game-header.blue {
	background: #1489ac;
}

.match-details-game-header span {
	display: flex;
}

.match-details-game-header .statistics img {
	display: flex;
	height: 15px;
	transition: 0.3s;
	opacity: 0.7;
	cursor: pointer;
}

.match-details-game-header .statistics img:hover {
	opacity: 1;
}

.match-details-game-header .text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 96%;
	display: inline-block;
}

/* MATCH DETAILS GAME HEADER AREA CSS END */
/* MATCH DETAILS GAME TAB AREA CSS START */
.nav.match-details-game-tab a {
	display: flex;
	color: $tertiary-color-shade-3;
	background: #ccc;
	height: 35px;
	align-items: center;
	padding: 0 16px;
	margin-right: 5px;
	border-radius: 0;
	font-size: 13px;
	transition: 0.3s;
	border-top: 3px solid #ccc;
}

.nav.match-details-game-tab a:hover,
.nav.match-details-game-tab a.active {
	background: #f9f9f9;
	border-top-color: $primary-color-shade-3;
}

.nav.match-details-game-tab.green a:hover,
.nav.match-details-game-tab.green a.active {
	border-top-color: $primary-color-shade-6;
}

.nav.match-details-game-tab.purple a:hover,
.nav.match-details-game-tab.purple a.active {
	border-top-color: #473067;
}

.nav.match-details-game-tab.megenta a:hover,
.nav.match-details-game-tab.megenta a.active {
	border-top-color: #a61f67;
}

.nav.match-details-game-tab.black a:hover,
.nav.match-details-game-tab.black a.active {
	border-top-color: #1b1b1b;
}

.nav.match-details-game-tab.orange a:hover,
.nav.match-details-game-tab.orange a.active {
	border-top-color: #d96c23;
}

.nav.match-details-game-tab.blue a:hover,
.nav.match-details-game-tab.blue a.active {
	border-top-color: #1489ac;
}

span.match-details-game-tab-favourite {
	position: relative;
}

span.match-details-game-tab-favourite i.zmdi {
	font-size: 22px;
	display: block;
}

span.match-details-game-tab-favourite .number {
	position: absolute;
	right: -10px;
	top: -4px;
	border: 1px solid #777;
	width: 12px;
	height: 12px;
	font-size: 8px;
	border-radius: 50%;
	z-index: 1;
	text-align: center;
	line-height: 10px;
	color: #666;
}

.nav.match-details-game-tab {
	background: #eeeeee;
	padding: 5px;
	border-radius: 0;
	margin-bottom: 5px;
	margin-top: 5px;
}

.match-details-game-tab-area [class*="col-lg-"] {
	padding-left: 0;
	padding-right: 0;
}

.match-details-game-tab-area .pre-match-bets-row .col-lg-6 {
	border-right: 1px solid #0e4835;
}

.match-details-game-tab-area .pre-match-bets-row .col-lg-6:nth-child(2n) {
	border-right: none;
}

.match-details-game-tab-area .single-match-result {
	background: transparent;
	margin-top: 0;
}

.nav.match-details-game-tab-2 {
	margin-bottom: 5px;
}

.nav.match-details-game-tab-2 .single-event-type-list-item img {
	max-height: 40px;
	height: 40px;
	max-width: max-content;
	width: auto;
}

.nav.match-details-game-tab-2 .single-event-type-list-item {
	height: 55px;
	max-width: 55px;
}

.nav.match-details-game-tab-2 .single-event-type-list-item.active,
.nav.match-details-game-tab-2 .single-event-type-list-item:hover {
	max-width: 260px;
}

.nav.match-details-game-tab-2 .single-event-type-list-item .name {
	font-size: 18px;
	margin-right: 6px;
}

/* MATCH DETAILS GAME TAB AREA CSS END */
/* MATCH DETAILS GAME INFO AREA CSS START */
.match-details-game-info-area {
	background: #eee;
	margin-top: 5px;
	padding: 5px;
	border-radius: 0;
}

.match-details-game-info-wrapper {
	background: #e5e5e5;
	display: flex;
	align-items: center;
	font-size: 12px;
	padding: 0 6px;
}

.match-details-game-info-wrapper .icon {
	margin-right: 10px;
}

/* MATCH DETAILS GAME INFO AREA CSS END */
/****************************************
            ADMIN PAGE CSS
*****************************************/
.admin-template-area {
	overflow: hidden;
	background: #124d38;
	padding: 5px;
}

.admin-content {
	background: #22815e;
	display: flex;
	flex-direction: column;
	margin-left: 5px;
	max-width: calc(83.333333% - 5px);
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: calc(83.333333% - 5px);
	padding: 0;
	overflow: hidden;
	border-radius: 5px;
}

/* ADMIN LEFT SIDEBAR CSS START */

.admin-profile-image {
	width: 150px;
	height: 150px;
	background-size: cover;
	background-position: center;
	background-color: $fourth-color-shade-1;
	border-radius: 50%;
	position: relative;
	z-index: 1;
	cursor: pointer;
	margin: 0 auto;
}

.admin-left-sidebar-toggle-btn-wrapper {
	background: $primary-color;
	color: $fourth-color-shade-1;
	display: flex;
	justify-content: space-between;
	padding: 12px;
	height: 40px;
	font-size: 13px;
	align-items: center;
	border-radius: 0;
	margin-bottom: 5px;
}

.admin-left-sidebar-toggle-btn-wrapper .icon {
	font-size: 22px;
	display: flex;
	cursor: pointer;
}

.admin-profile-info-area {
	background: #22815e;
	border-radius: 5px 5px 0 0;
	padding: 20px;
	color: #fff;
}

span.admin-profile-image-hover {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: $fourth-color-shade-1;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 50%;
	font-size: 14px;
	transition: 0.3s;
	opacity: 0;
	visibility: hidden;
	cursor: pointer;
}

span.admin-profile-image-hover i.zmdi {
	font-size: 24px;
	margin-bottom: 7px;
}

.admin-profile-image:hover span.admin-profile-image-hover {
	opacity: 1;
	visibility: visible;
}

.admin-profile-username {
	font-weight: 700;
}

.admin-profile-id {
	padding: 5px 10px;
	font-size: 12px;
	color: #fff;
}

.admin-profile-info-show-more-header {
	text-align: right;
	padding: 10px;
	font-size: 11px;
	cursor: pointer;
	transition: 0.3s;
}

.admin-profile-info-show-more-header:hover {
	color: #777;
}

.admin-profile-info-show-more-header[aria-expanded="true"] .show-more,
.admin-profile-info-show-more-header[aria-expanded="false"] .show-less {
	display: none;
}

.admin-profile-info-show-more-body .top-part {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	display: flex;
	height: 70px;
	justify-content: space-around;
	align-items: center;
	padding: 12px 0;
}

span.registration-login-date {
	display: flex;
	flex-direction: column;
	font-size: 10px;
	color: #777;
	padding: 0 15px;
}

span.registration-login-date .date {
	color: #111;
	font-weight: 700;
	font-size: 12px;
	margin-top: 5px;
}

span.vertical-divider {
	width: 1px;
	height: 100%;
	background: #ccc;
}

.admin-profile-info-show-more-body .bottom-part {
	padding: 12px 0;
}

.admin-profile-balance {
	background: #1c2b3a;
	color: #fff;
	border-radius: 0;
	padding: 15px;
}

.main-balance h4 {
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.main-balance p {
	font-size: 12px;
	margin-bottom: 0;
}

.main-balance {
	margin-bottom: 15px;
}

.bonus-balance h5 {
	font-size: 11px;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.bonus-balance p {
	font-size: 10px;
	margin-bottom: 0;
}

.bonus-balance {
	opacity: 0.8;
}

.admin-profile-sidebar-option-header {
	background: $primary-color-shade-3;
	color: $fourth-color-shade-1;
	height: 45px;
	display: flex;
	padding: 12px;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	cursor: pointer;
	position: relative;
	transition: 0.3s;
	border-radius: 0;
}

.admin-profile-sidebar-option-header[aria-expanded="true"] {
	border-radius: 0;
}

.admin-profile-sidebar-option-header:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 3px;
	background: $primary-color-shade-3;
	content: "";
	border-radius: 0;
	transition: 0.3s;
	opacity: 0;
	visibility: hidden;
}

.admin-profile-sidebar-option-header:hover:before,
.left-sidebar:not(.collaps-active)
	.admin-profile-sidebar-option-header[aria-expanded="true"]:before,
.left-sidebar.collaps-active
	.admin-profile-sidebar-option-header.active:before {
	opacity: 1;
	visibility: visible;
}

.admin-profile-sidebar-option-header .icon {
	font-size: 20px;
	margin-right: 10px;
	color: $fourth-color-shade-1;
}

.admin-profile-sidebar-option-header span {
	display: flex;
	align-items: center;
}

.admin-profile-sidebar-option-header[aria-expanded="true"]
	.accordion-icon
	i.fas.fa-chevron-down,
.admin-profile-sidebar-option-header[aria-expanded="false"]
	.accordion-icon
	i.fas.fa-chevron-up {
	display: none;
}

.admin-profile-sidebar-option-header-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.admin-profile-sidebar-option-body {
	background: #edecef;
	padding: 5px;
}

.admin-profile-sidebar-option-body a {
	display: flex;
	flex-direction: column;
	width: 100%;
	color: $tertiary-color-shade-3;
	background: $fourth-color-shade-1;
	height: 35px;
	padding: 10px;
	justify-content: center;
	margin-bottom: 5px;
	transition: 0.3s;
	position: relative;
	border-radius: 0;
	cursor: pointer;
	font-size: 13px;
	font-weight: 400;
}

.admin-profile-sidebar-option-body a:last-child {
	margin-bottom: 0;
}

.admin-profile-sidebar-option-body a:hover,
.admin-profile-sidebar-option-body a.active {
	font-weight: 700;
	padding-left: 15px;
}

.admin-profile-sidebar-option-body a:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 3px;
	background: $primary-color-shade-3;
	content: "";
	border-radius: 0;
	transition: 0.3s;
	opacity: 0;
	visibility: hidden;
}

.admin-profile-sidebar-option-body a:hover:before,
.admin-profile-sidebar-option-body a.active:before {
	opacity: 1;
	visibility: visible;
}

.admin-profile-sidebar-option-area {
	position: relative;
}

.admin-profile-left-sidebar-footer {
	background: $primary-color-shade-3;
	flex: 1;
	border-radius: 0;
	transition: 0.3s;
	margin-top: 5px;
}

.admin-profile-sidebar-option {
	margin-top: 5px;
}

/* Avater Upload Area Css Start */
.admin-avater-list-area,
.admin-menu-list-area {
	background: $fourth-color-shade-1;
	position: fixed;
	top: 123px;
	left: 17.6%;
	z-index: 7000;
	width: 400px;
	box-shadow: 0 0 7px #999;
	border-radius: 0;
	opacity: 0;
	visibility: hidden;
	transition: 0.3s;
	padding-top: 10px;
}

.admin-menu-list-area {
	left: 70px !important;
}

.admin-avater-list-area.active,
.admin-menu-list-area.active {
	opacity: 1;
	visibility: visible;
}

/* .admin-menu-list-area .admin-profile-sidebar-option-body {
    display: none;
}
.admin-menu-list-area .admin-profile-sidebar-option-body.active {
    display: block;
} */
span.admin-avater-list-area-close {
	position: absolute;
	right: 0;
	top: 0;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.admin-avater-upload-droppable-area {
	width: 100%;
	height: 160px;
	border: 2px dashed #bbb;
	cursor: pointer;
	position: relative;
}

.admin-avater-upload-input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

.admin-avater-upload-area {
	padding: 20px;
	border-bottom: 1px solid #ccc;
	display: none;
}

.admin-avater-upload-instruction {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.admin-avater-upload-instruction span {
	color: #777;
	font-size: 14px;
	margin-bottom: 5px;
	display: flex;
}

.admin-avater-upload-instruction .up-btn {
	background: #999;
	color: $fourth-color-shade-1;
	font-size: 13px;
	padding: 8px 22px;
	border-radius: 0;
	height: 35px;
	align-items: center;
	justify-content: center;
}

.admin-avater-upload-instruction .size {
	font-size: 12px;
	font-style: italic;
	margin-top: 4px;
}

.admin-avater-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	min-height: 280px;
	padding: 6px;
	overflow: hidden;
	overflow-y: auto;
}

.admin-single-avater-item img {
	max-height: 60px;
}

.admin-single-avater-item {
	display: flex;
	margin: 7px 12px;
	cursor: pointer;
	transition: 0.3s;
	border: 4px solid #eee;
	border-radius: 50%;
}

.admin-single-avater-item:hover,
.admin-single-avater-item.active {
	border-color: $primary-color-shade-3;
}

button.admin-avater-save-btn {
	display: flex;
	margin: 15px auto 25px;
	text-align: center;
	background: $primary-color-shade-4;
	color: $fourth-color-shade-1;
	border: none;
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	font-size: 15px;
	padding: 5px 30px;
	border-radius: 0;
	height: 35px;
	align-items: center;
	justify-content: center;
	line-height: 15px;
	cursor: pointer;
}

.admin-avater-list-area-arrow,
.admin-menu-list-area-arrow {
	position: absolute;
	left: -10px;
	top: 23px;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	border-right: 10px solid #ffff;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	content: "";
	z-index: 200000;
}

/* Avater Upload Area Css End */
/* ADMIN LEFT SIDEBAR CSS END */
/* ADMIN RIGHT SIDEBAR CSS START */
.admin-content .tab-content .tab-pane {
	/* height: 100% !important; */
	width: 100%;
}

.admin-content .tab-content .tab-pane .row {
	height: auto !important;
}

.admin-content .tab-content {
	display: flex;
	flex: 1;
	border: 1px solid #ddd;
	border-radius: 0;
	border-top: none;
	padding: 20px;
	overflow: hidden;
	overflow-y: auto;
	position: relative;
}

.admin-page-close-btn {
	position: absolute;
	right: 18px;
	top: 5px;
	font-size: 25px;
	color: #999;
	cursor: pointer;
	transition: 0.3s;
}

.admin-page-close-btn:hover {
	color: #666;
}

.admin-content a.nav-item.nav-link {
	color: $tertiary-color-shade-0;
	font-size: 14px;
	margin: 0;
	padding: 6px 40px;
	border-radius: 0;
	border: none;
	border-right: 1px solid #ddd;
	border-top: 1px solid #ddd;
	position: relative;
	white-space: nowrap;
	cursor: pointer;
}

.admin-content .nav.nav-tabs {
	overflow: hidden;
	overflow-x: auto;
	flex-wrap: nowrap;
}

.admin-content a.nav-item.nav-link:first-child {
	border-top-left-radius: 4px;
	border-left: 1px solid #ddd;
}

.admin-content a.nav-item.nav-link:last-child {
	border-top-right-radius: 4px;
}

.admin-content a.nav-item.nav-link:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 3px;
	background: $fourth-color-shade-1;
	content: "";
	transition: 0.3s;
}

.admin-content a.nav-item.nav-link:hover:before,
.admin-content a.nav-item.nav-link.active:before {
	background: $primary-color-shade-3;
}

.admin-content a.nav-item.nav-link:first-child:hover:before,
.admin-content a.nav-item.nav-link:first-child.active:before {
	border-top-left-radius: 4px;
}

.admin-content a.nav-item.nav-link:last-child:hover:before,
.admin-content a.nav-item.nav-link:last-child.active:before {
	border-top-right-radius: 4px;
}

.admin-content a.nav-item.nav-link.active {
	color: $primary-color-shade-3;
	font-weight: 700;
}

.admin-content-header {
	margin-bottom: 15px;
}

.admin-profile-update-form .form-group {
	margin: 15px 0;
}

.admin-profile-update-form .form-group label {
	font-weight: 700;
	color: #19674b;
	font-size: 13px;
	display: block;
}

.admin-profile-update-form .form-group .form-control {
	background: #dbdbdb;
	background: linear-gradient(to bottom, #e7e7e7 0%, #dbdbdb 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#e7e7e7", endColorstr="#dbdbdb",GradientType=0 );
	color: #000;
	border-color: #9f9f9f;
	border-radius: 0 6px 6px 0;
	font-size: 14px;
	height: 35px;
	position: relative;
}

.admin-profile-update-form
	.form-group
	.form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #111;
}
.admin-profile-update-form .form-group .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #111;
}
.admin-profile-update-form .form-group .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #111;
}
.admin-profile-update-form .form-group .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #111;
}

.admin-profile-update-form .form-group .form-control:-webkit-autofill,
.admin-profile-update-form .form-group .form-control:-webkit-autofill:hover,
.admin-profile-update-form .form-group .form-control:-webkit-autofill:focus {
	border: 1px solid #9f9f9f;
	-webkit-text-fill-color: #000;
	-webkit-box-shadow: 0 0 0px 1000px #dbdbdb inset;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
}

.admin-profile-update-form .form-group .form-control option {
	color: #000;
}

.admin-profile-update-form .form-group .form-control:disabled,
.admin-profile-update-form .form-group .form-control[readonly] {
	background: #ffffff;
    border-color: #88888b;
    font-size: 1.5em;
}

.input-group.date.custom-bootstrap-ui-datepicker .input-group-addon {
	position: absolute;
	right: 11px;
	top: 48%;
	transform: translateY(-50%);
	z-index: 1000;
}

.admin-profile-update-form .form-group .form-control:focus {
	outline: 0 solid;
	box-shadow: 0 0 0 0;
}

.admin-profile-update-form .form-group textarea.form-control {
	height: 160px;
	border-radius: 5px;
}

.admin-profile-update-form
	.form-group
	.select2-container
	.select2-selection--single {
	height: 35px !important;
	background: #f9f9f9;
}

.admin-profile-update-form
	.form-group
	.ng2-floating-select-2
	.select2-container
	.select2-selection--single {
	border: none;
}

.admin-profile-update-form
	.form-group
	.select2-container
	.select2-selection--single
	.select2-selection__rendered {
	padding-top: 5px;
	padding-bottom: 5px;
	height: 100%;
	display: flex;
	align-items: center;
	border: 1px solid #ddd;
	border-radius: 0;
}

.admin-profile-update-form
	.form-group
	.ng2-floating-select-2
	.select2-container
	.select2-selection--single
	.select2-selection__rendered {
	padding: 0.375rem 0.75rem !important;
	padding-right: 28px !important;
}

.admin-profile-update-form
	.form-group
	.ng2-floating-select-2
	.select2-container--default
	.select2-selection--single
	.select2-selection__arrow
	b {
	margin-top: -3px;
}

.admin-profile-update-form
	.form-group
	.ng2-floating-select-2
	.ngx-select__toggle:hover {
	color: #333;
	background-color: #f9f9f9 !important;
	border-color: #ddd !important;
}

.admin-profile-update-form-submit {
	background: #face08;
	color: #19674b;
	border: none;
	margin-top: 30px;
	border-radius: 6px;
	/* font-family: "ProximaNovaCondensedRegular",sans-serif; */
	font-size: 13px;
	padding: 8px 30px;
	transition: 0.3s;
	cursor: pointer;
	margin-bottom: 30px;
	text-transform: uppercase;
	font-weight: 600;
}

.admin-profile-update-form-submit:hover {
	background: lighten(#face08, 10%);
	color: #19674b;
}

.admin-profile-update-form-submit:focus {
	outline: 0 solid;
}

.admin-profile-update-form-submit.disabled {
	opacity: 0.7;
	cursor: default;
}

.form-group-input-wrapper {
	position: relative;
}

.row.admin-profile-table.justify-content-center .col-lg-12 {
	padding-left: 0;
	padding-right: 0;
}

.admin-profile-table .table tbody td {
	border-top: none;
	border-bottom: 1px solid $primary-color;
	transition: 0.3s;
	vertical-align: middle;
	font-size: 14px;
	color: #fff;
}

.admin-profile-table .table tbody tr:first-child td {
	border-top: 1px solid $primary-color;
}

.admin-profile-table .table tbody tr:nth-child(2n) td {
	background: #3b5169;
}

.admin-profile-table .table tbody tr:nth-child(2n + 1) td {
	background: #2b3e52;
}

.admin-profile-table .table.table thead th {
	border-bottom: 1px solid #1c2b3a;
	background: #1c2b3a;
	color: $fourth-color-shade-1;
	font-size: 14px;
	vertical-align: middle;
	white-space: nowrap;
}

.admin-profile-table .table tbody td small {
	display: block;
}

.table-pagination {
	text-align: center;
	margin-bottom: 30px;
	margin-top: 30px;
}

.table-pagination ul {
	display: flex;
	justify-content: center;
}

.table-pagination ul li {
	display: flex;
}

.table-pagination ul li a {
	display: flex;
	width: 25px;
	height: 25px;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	border: 1px solid #ddd;
	border-left: none;
	color: #1b222d;
	transition: 0.3s;
	margin: 0;
}

.table-pagination ul li:first-child a {
	border-left: 1px solid #ddd;
}

.table-pagination ul li a:hover,
.table-pagination ul li.active a {
	color: $fourth-color-shade-1;
	background: $primary-color-shade-3;
}

.table-pagination ul li.active a {
	cursor: default;
	pointer-events: none;
}

#nav-view-profile table tbody tr td:first-child {
	font-weight: 700;
}

.admin-profile-condition {
	position: relative;
	min-height: 35px;
	border-radius: 0;
	background: #eee;
	padding: 8px;
	font-size: 14px;
	padding-left: 12px;
	padding-right: 40px;
	margin-bottom: 15px;
}

.admin-profile-condition .icon {
	position: absolute;
	right: 10px;
	top: 50%;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 8px;
	background: #ccc;
	transform: translateY(-50%);
}

.admin-profile-condition .colored-text {
	display: block;
	color: #e44a3f;
}

a.action-refund {
	background: #e44a3f;
	color: #fff;
	font-size: 10px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 5px 10px;
	transition: 0.3s;
	cursor: pointer;
}

a.action-refund:hover {
	background: #c3362c;
	color: $fourth-color-shade-1;
}

/* ADMIN RIGHT SIDEBAR CSS END */
/* ADMIN COLLAPS ACTION CSS START */
.admin-content.collaps-active {
	-ms-flex: 0 0 calc(100% - 66px);
	flex: 0 0 calc(100% - 66px);
	max-width: calc(100% - 66px);
}

.admin-left-sidebar-toggle-btn-wrapper .text {
	white-space: nowrap;
}

.collaps-active .admin-profile-image {
	width: 35px;
	height: 35px;
}

.collaps-active .admin-left-sidebar-toggle-btn-wrapper,
.collaps-active .admin-profile-sidebar-option-header {
	justify-content: center;
}

.collaps-active .admin-left-sidebar-toggle-btn-wrapper .icon {
	width: 42px;
	height: 40px;
	justify-content: center;
	align-items: center;
}

.collaps-active .admin-left-sidebar-toggle-btn-wrapper {
	padding: 0;
}

.collaps-active .admin-profile-sidebar-option-header .icon {
	font-size: 24px;
	margin-right: 0;
	cursor: pointer;
}

.collaps-active .admin-profile-sidebar-option-header .left-side {
	padding-right: 0;
}

.collaps-active .admin-profile-sidebar-option-header {
	padding: 0;
}

.collaps-active .admin-profile-sidebar-option-header-wrapper {
	justify-content: center;
	height: 100%;
}

.collaps-active .admin-profile-sidebar-option-header[aria-expanded="true"] {
	border-radius: 0;
}

.admin-menu-list-area {
	padding-top: 0;
	width: 200px;
}

.admin-menu-list-area.profile {
	top: 161px;
}

.admin-menu-list-area.profile.active {
	top: 171px;
}

.admin-menu-list-area.wallet {
	top: 211px;
}

.admin-menu-list-area.wallet.active {
	top: 221px;
}

.admin-menu-list-area.help {
	top: 261px;
}

.admin-menu-list-area.help.active {
	top: 271px;
}

.admin-menu-list-area-arrow {
	border-right: 10px solid #cecece;
}

.admin-profile-sidebar-option-header.active:before {
	opacity: 1;
	visibility: visible;
}

/* ADMIN COLLAPS ACTION CSS START */
/* USER PROFILE AFTER LOGIN CSS START */
a.logged-in-user {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0;
}

a.logged-in-user span {
	display: flex;
	padding: 0;
	border: none;
}

a.logged-in-user span.user-image {
	flex-shrink: 0;
}

a.logged-in-user span.user-image img {
	height: 40px;
	width: auto;
	display: block;
}

a.logged-in-user span.user-balance {
	flex-direction: column;
	font-size: 13px;
	margin-left: 10px;
}

a.logged-in-user span.user-balance .bottom-balance {
	font-size: 10px;
	color: #15ce05;
	font-weight: 700;
}

.after-login-dropdown-menu .nav {
	flex-direction: column;
	text-align: center;
	padding: 5px;
}

.after-login-dropdown-menu .nav a {
	color: $fourth-color-shade-1;
	border-color: $fourth-color-shade-1;
	margin-bottom: 5px;
	font-size: 15px;
}

.after-login-dropdown-menu .nav a:last-child {
	margin-bottom: 0;
}
.after-login-dropdown-menu .nav a:hover,
.after-login-dropdown-menu .nav a.active {
	background: $fourth-color-shade-1;
	color: $tertiary-color-shade-0;
}

.dropdown-menu.after-login-dropdown-menu {
	padding: 0;
	/* top: 10px !important; */
}

/* USER PROFILE AFTER LOGIN CSS END */
/* SOME GENERAL CSS START */
.form-control:focus {
	border-color: transparent;
	outline: 0;
	box-shadow: 0 0 0 0;
}

/* SOME GENERAL CSS END */
/* RESPONSIVE SLICKNAV MENU CSS START */
.slicknav_menu {
	display: none;
}

.slicknav_menu {
	background: transparent;
	padding: 0;
}

.slicknav_btn {
	margin: 0;
	text-shadow: none;
	background-color: #000;
	padding: 8px 12px;
	font-size: 22px;
}

.slicknav_nav {
	position: fixed;
	background: #1e2531;
	width: 100%;
	left: 0;
	top: 64px;
	z-index: 10000;
	border-bottom: 3px solid #a61d67;
	text-align: left;
}

.slicknav_nav li {
	border-top: 1px solid #555;
}

.slicknav_nav li a i.fas {
	display: none;
}

.slicknav_nav .slicknav_row:hover,
.slicknav_nav a:hover {
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	background: #15ce05;
	color: #1e2531;
}

.slicknav_nav .slicknav_row:hover a {
	color: #1e2531;
}

.slicknav_nav a {
	font-size: 18px;
}

/* RESPONSIVE SLICKNAV MENU CSS END */
/* RESPONSIVE CUSTOM MENU CSS START */
.responsive-main-menu-wrapper,
.responsive-main-menu .dropdown-menu {
	position: fixed !important;
	background: $primary-color-shade-3;
	width: 100%;
	left: 0;
	z-index: 10000;
	text-align: left;
}

.responsive-main-menu-wrapper {
	top: 64px !important;
	border-bottom: 3px solid $secondary-color-shade-1;
}

.responsive-main-menu .dropdown-menu {
	transform: translate3d(0px, 0px, 0px) !important;
	margin: 0 !important;
	padding: 0 !important;
	border: none !important;
	border-radius: 0 !important;
}

.responsive-main-menu .dropdown-menu .responsive-main-menu-wrapper {
	-webkit-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	-moz-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	-ms-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	-o-transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	transition: max-height 0.3s, opacity 0.2s 0.1s, visibility 0s 0.3s;
	max-height: 0;
	display: block;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
}

.responsive-main-menu.show .dropdown-menu .responsive-main-menu-wrapper {
	-webkit-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	-moz-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	-ms-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	-o-transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	transition: max-height 0.3s, opacity 0.2s, visibility 0s;
	max-height: 390px;
	opacity: 1;
	visibility: visible;
}

.responsive-main-menu-wrapper li {
	border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.responsive-main-menu-wrapper li a:hover {
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	background: $secondary-color-shade-1;
	color: $primary-color-shade-1;
}

.responsive-main-menu-wrapper li a {
	font-size: 18px;
	color: $fourth-color-shade-1;
	padding: 5px 10px;
	margin: 2px 5px;
	display: block;
}

.responsive-main-menu-wrapper ul {
	flex-direction: column;
}

.responsive-main-menu-wrapper ul li {
	width: 100%;
}

.responsive-main-menu-wrapper ul li ul {
	transition: 0.3s;
	opacity: 0;
	visibility: hidden;
	height: 0;
}

.responsive-main-menu-wrapper ul li:hover ul {
	opacity: 1;
	visibility: visible;
	height: max-content;
}

.responsive-main-menu button,
.responsive-main-menu a.dropdown-toggle {
	cursor: pointer;
	background: transparent;
	color: $fourth-color-shade-1;
	border: none;
	font-size: 20px;
	padding: 4px 15px;
	border-radius: 0;
}

.responsive-main-menu .dropdown-toggle::after {
	display: none;
}

.responsive-main-menu-wrapper ul li ul li a {
	font-size: 90%;
	padding-left: 25px;
}

/* RESPONSIVE CUSTOM MENU CSS END */
/* PRELOADER CSS START */
#preloader {
	position: fixed;
	z-index: 25000;
	width: 100%;
	height: 100%;
	background: $primary-color-shade-1;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
}

#preloader img {
	max-height: 60px;
}

.preloader-spin {
	display: flex;
	justify-content: center;
	margin-top: 35px;
}

#preloader img.preloader-image {
	max-height: 30px;
}

/* PRELOADER CSS END */
/* LIVE EVENT FOR MOBILE CSS START */
.live-events-in-mobile {
	background: #ddd;
	padding: 10px 6px;
	display: none;
}

.live-events-in-mobile-wrapper {
	overflow: hidden;
	overflow-x: auto;
	display: flex;
	flex-wrap: nowrap;
	flex-shrink: 0;
}

.live-events-in-mobile-wrapper::-webkit-scrollbar {
	display: none;
}

.single-live-events-in-mobile {
	background: $fourth-color-shade-1;
	border-radius: 0;
	margin-right: 10px;
	width: 94%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex-shrink: 0;
	padding: 13px;
	min-height: 131px;
}

.single-live-events-in-mobile:last-child {
	margin-right: 0;
}

.single-live-events-in-mobile-header span {
	display: flex;
}

span.single-live-events-in-mobile-icon img {
	max-height: 20px;
}

.single-live-events-in-mobile-header {
	display: flex;
	align-items: center;
}

span.single-live-events-in-mobile-icon {
	margin-right: 10px;
}

span.single-live-events-in-mobile-name {
	font-weight: 700;
	font-size: 14px;
}

.single-live-events-in-mobile-team-name-score {
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
}

.single-live-events-in-mobile-team-name-score span {
	display: flex;
	align-items: center;
}

span.single-live-events-in-mobile-team-name {
	width: 84%;
	overflow: hidden;
}

span.single-live-events-in-mobile-team-score .team-score {
	background: transparent;
	color: $primary-color-shade-4;
	font-size: 15px;
	padding: 0;
	min-width: auto;
}

span.single-live-events-in-mobile-team-score .separator {
	font-size: 15px;
	font-weight: 700;
	color: #aaa;
	margin: 0 5px;
}

span.single-live-events-in-mobile-team-name .team-name {
	display: inline-block;
	color: $tertiary-color-shade-3;
}

span.single-live-events-in-mobile-team-name .separator {
	margin: 0 5px;
}

.single-live-events-in-mobile-summary {
	font-size: 12px;
	color: #777;
}

/* LIVE EVENT FOR MOBILE CSS END */

.tab-d-none {
	display: none;
}

/* SOME FIX AFTER CONVERTING ANGULAR START */
app-user-profile,
app-user-wallet,
app-help-desk {
	display: flex;
	flex-direction: column;
	height: 100% !important;
}

.admin-content .tab-content {
	height: calc(100% - 70px);
}

app-view-profile,
app-edit-profile,
app-change-club,
app-my-followers,
app-bet-history,
app-change-password,
app-make-deposit,
app-deposit-history,
app-coin-transfer,
app-withdraw,
app-withdraw-history,
app-statement,
app-submit-complain,
app-complain-history {
	width: 100%;
}

/* SOME FIX AFTER CONVERTING ANGULAR END */
/* DATATABLE CSS START */
.dataTables_wrapper .dataTables_length {
	font-size: 13px !important;
	font-weight: 700 !important;
}

.dataTables_wrapper .dataTables_length select {
	border-radius: 0;
	margin: 0 5px;
	padding: 0 8px;
}

.dataTables_wrapper .dataTables_length select:focus {
	outline: 0 solid;
}

.dataTables_wrapper .dataTables_filter input[type="search"] {
	border: 1px solid #ccc;
	border-radius: 0;
	color: #212529 !important;
	font-size: 13px;
	height: 30px;
	padding: 0 15px;
}

.dataTables_wrapper .dataTables_filter {
	font-size: 13px;
	font-weight: 700;
	margin-bottom: 5px;
}

.dataTables_wrapper .dataTables_filter input[type="search"]:focus {
	outline: 0 solid;
}

table.dataTable.no-footer {
	border-bottom-color: #dee2e6 !important;
}

table.dataTable {
	margin-bottom: 12px !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
	color: #212529 !important;
}

.dataTables_wrapper .dataTables_info {
	font-size: 13px !important;
	font-weight: 700 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	font-size: 13px !important;
	background: #fff !important;
	border-color: #ccc !important;
	width: 30px !important;
	height: 30px !important;
	text-align: center !important;
	padding: 0 !important;
	line-height: 29px !important;
	margin: 0 3px !important;
	color: #212529 !important;
	border-radius: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate span .paginate_button.current,
.dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover {
	background: $primary-color-shade-3 !important;
	color: $fourth-color-shade-1 !important;
	border-color: $primary-color-shade-3 !important;
	border-radius: 0 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
	width: auto !important;
	padding: 0 10px !important;
	border-color: transparent !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
	background: transparent !important;
	color: #1a5685 !important;
}

.dataTables_wrapper .dataTables_paginate {
	margin-bottom: 20px !important;
}

/* DATATABLE CSS END */
/* ANGULAR MATERIAL LODING BAR CSS START */
.mat-progress-bar {
	position: absolute !important;
	z-index: 2002;
	bottom: 0;
	left: 0;
	height: 2px !important;
}

.mat-progress-bar-secondary.mat-progress-bar-fill.mat-progress-bar-element {
	background: #ff1b1b;
}

.mat-progress-bar-buffer.mat-progress-bar-element {
	background: #ffdf1b;
}

/* ANGULAR MATERIAL LODING BAR CSS END */
/* NEW LAYOUT CSS START */
.logo-list.logo-list-stacked ul li {
	background: #fff;
	width: 75px;
	height: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border-radius: 8px;
	margin: 5px;
	flex-direction: column;
}

.logo-list.logo-list-stacked ul {
	display: flex;
	flex-wrap: wrap;
}

.footer-bg-area {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.left-sidebar,
.game-area,
.right-sidebar {
	background: transparent;
	padding: 0;
}

.favourite-card {
	margin-bottom: 5px;
}

.left-sidebar-tab-search-wrapper {
	margin-top: 5px;
	margin-bottom: 5px;
}

.betslip-count-box {
	position: fixed;
	right: 0;
	top: 50%;
	background: #14805e;
	width: 80px;
	height: 70px;
	z-index: 10000;
	display: flex;
	color: #fff;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	font-weight: 700;
	cursor: pointer;
	transition: 0.3s;
	box-shadow: 0 0 8px #4a7568;
	border-left: 3px solid #15ce05;
	text-transform: uppercase;
}

.betslip-count-box:before {
	position: absolute;
	left: 5px;
	top: 5px;
	width: calc(100% - 10px);
	height: calc(100% - 10px);
	border: 1px solid #fff;
	content: "";
	opacity: 0.3;
}

.betslip-count-box span.betslip-text-wrapper {
	position: relative;
	z-index: 1;
}

.betslip-count-box span.betslip-text-wrapper .number {
	position: absolute;
	right: -5px;
	top: -1px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #15ce05;
	content: "";
	color: #14805e;
	transition: 0.3s;
	-webkit-animation: scale-up-center 2s cubic-bezier(0.68, -0.55, 0.265, 1.55)
		infinite alternate both;
	animation: scale-up-center 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite
		alternate both;
}

@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.2);
		transform: scale(0.2);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.2);
		transform: scale(0.2);
	}

	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.betslip-collapse {
	background: #14805e;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;
	font-size: 13px;
	height: 34px;
	padding: 10px;
	width: 100%;
	cursor: pointer;
	margin-bottom: 5px;
}

.betslip-collapse span {
	display: flex;
	align-items: center;
}

.betslip-card-header.no-border {
	border: none;
}

.header-area {
	padding: 0;
}

.container-fluid.header-top-area {
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	padding: 0;
	height: 40px;
}

.header-top-left,
.header-top-left ul:not(.dropdown-menu),
.header-top-right,
.header-top-right ul:not(.dropdown-menu) {
	display: flex;
	align-items: center;
	justify-content: left;
}

.header-top-left ul li a {
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	border-right: 1px solid rgba(255, 255, 255, 0.3);
	transition: 0.3s;
	color: #fff;
}

.header-top-left ul li a:hover {
	background: darken(#1e2531, 10%);
	color: #15ce05;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.header-top-right,
.header-top-right ul:not(.dropdown-menu) {
	justify-content: flex-end;
}


.language-list .btn {
	background: transparent;
	padding: 0;
	display: flex;
	align-items: center;
	color: #fff;
	border: none;
}

.language-list img:not(.dropdown-icon) {
	height: 16px;
	border-radius: 50%;
	width: 16px;
}

.language-list .dropdown-menu li a img {
	margin-right: 10px;
}

.language-list .dropdown-menu li a {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 13px;
	color: #fff;
}

.language-list .dropdown-menu {
	background: #14805e;
	border-radius: 0;
	border: none;
	box-shadow: 0 0 5px #334e45;
}

.header-top-right ul li a,
.header-top-right ul li button,
.language-list .btn {
	font-size: 13px;
	font-weight: 600;
	height: 40px;
	display: flex;
	align-items: center;
	border-left: 1px solid rgba(255, 255, 255, 0.3);
	padding: 0 20px;
}

.header-top-right ul li a:hover,
.header-top-right ul li button:hover,
.header-top-right ul li a[aria-expanded="true"],
.header-top-right ul li button[aria-expanded="true"] {
	background: darken(#1e2531, 10%);
	color: $secondary-color-shade-1;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.header-top-right ul.dropdown-menu li a {
	border-left: none;
	padding: 0;
	height: auto;
}

.header-top-right ul.dropdown-menu li a:hover {
	border: none;
	background: transparent;
}

a.logged-in-user span.user-image img {
	height: 30px;
	width: 30px;
	border: 2px solid #fff;
	border-radius: 50%;
}

.main-menu ul li a {
	color: #fff;
	text-transform: uppercase;
	font-family: "ProximaNovaRegular", sans-serif;
	padding: 15px;
	font-weight: 600;
}

.container-fluid.header-bottom-area .row {
	height: 55px;
}

.game-content-area {
	margin-top: 0;
}

.game-other-info-box-area {
	margin-top: 103px;
	padding: 0 2px;
}

.game-other-info-box-area .row {
	margin: 0;
}

.game-other-info-box-area .col-lg-2,
.game-other-info-box-area .col-lg-4,
.game-other-info-box-area .col-lg-6,
.game-other-info-box-area .col-lg-9,
.game-other-info-box-area .col-lg-3 {
	padding-left: 3px;
	padding-right: 3px;
}

.game-other-info-registration-area {
	position: relative;
	background-size: cover;
	background-position: center;
	z-index: 1;
	color: #fff;
	padding: 50px 0;
}

.game-other-info-registration-wrapper {
	background: rgba(29, 36, 47, 0.851);
	padding: 10px;
	min-height: 100%;
}

.game-other-info-registration-area:before,
.sign-in-fullpage-area:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $primary-color;
	content: "";
	z-index: -1;
	opacity: 0.85;
}

.game-other-info-registration-area h3 {
	color: #fff;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 10px;
}

.after-login-dropdown-menu .nav a {
	margin-bottom: 0;
	font-size: 13px;
	height: 25px;
	padding: 0;
	border: none;
	border-top: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.after-login-dropdown-menu .nav a:hover,
.after-login-dropdown-menu .nav a.active {
	padding-left: 10px;
}

.header-area .header-bottom-area [class*="col-lg-"],
.header-area .header-bottom-area [class*="col-md-"] {
	padding-left: 15px;
	padding-right: 15px;
}

.betslip-card {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.single-banner-slider {
	height: 323px;
	background-size: cover;
	background-position: center;
	display: flex;
	padding: 50px 30px;
	color: #fff;
	transition: 0.3s;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.single-banner-slider:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $primary-color-shade-1;
	content: "";
	opacity: 0.7;
	z-index: -1;
}

.single-banner-slider h2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	margin-bottom: 20px;
	max-width: 100%;
	transition: 0.3s;
}

.single-banner-slider h2 span {
	display: block;
	text-transform: capitalize;
}

.single-banner-slider h2 span.heading-with-bg {
	display: inline-block;
	color: $primary-color-shade-1;
	background: #fff;
	font-size: 26px;
	text-transform: uppercase;
	font-weight: 900;
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	padding: 8px 12px;
	margin-bottom: 20px;
	transform: skewX(-5deg) rotate(-3deg);
}

.single-banner-slider a,
.single-banner-slider button {
	background: $secondary-color-shade-1;
	color: $fourth-color-shade-1;
	display: inline-block;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	padding: 8px 30px;
	transition: 0.3s;
	border: none;
	cursor: pointer;
}

.single-banner-slider a:hover,
.single-banner-slider button:hover {
	color: #fff;
	background: $primary-color-shade-3;
}

.single-banner-slider:hover {
	color: #fff;
}

.ngx-select__item_active {
	background-color: #14805e !important;
	border-radius: 0 !important;
}

.ngx-select__toggle.btn.form-control,
.ngx-select.dropdown.open.show .ngx-select__search.form-control {
	border: 1px solid #fff !important;
	border-radius: 0 !important;
	font-size: 14px !important;
	padding: 2px 10px !important;
	padding-top: 17px !important;
	height: 40px;
	line-height: 20px !important;
}

.ngx-select__toggle.btn.form-control:hover {
	background-color: #fff !important;
}

.ngx-select__search.form-control:focus {
	outline: 0 solid !important;
	box-shadow: 0 0 0 0 !important;
}

.banner-slider-carousel {
	position: relative;
}

.banner-slider-carousel .owl-dots {
	position: absolute;
	left: 30px;
	bottom: 30px;
}

.banner-slider-carousel .owl-dots button {
	height: 2px !important;
	width: 30px !important;
	display: inline-block !important;
	margin: 0 5px !important;
	transition: 0.2s;
	background: #fff !important;
}

.banner-slider-carousel .owl-dots button.active {
	background: #15ce05 !important;
}

.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper {
	margin-bottom: 8px;
	width: 100%;
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control,
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control {
	border-radius: 0;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.4);
	font-size: 13px;
	color: #fff;
	height: 35px;
	padding: 7px 12px;
}
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control
	option {
	color: #000;
}
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:-webkit-autofill,
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:-webkit-autofill:hover,
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:-webkit-autofill:focus {
	border: 1px solid rgba(255, 255, 255, 0.4);
	-webkit-text-fill-color: white;
	-webkit-box-shadow: 0 0 0px 1000px $primary-color inset;
	transition: background-color 5000s ease-in-out 0s;
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:focus,
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:focus {
	box-shadow: 0 0 0 0;
	background: transparent;
	border: 1px solid #fff;
	color: #fff;
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control::placeholder,
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #fff;
	opacity: 1;
	/* Firefox */
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:-ms-input-placeholder,
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control::-ms-input-placeholder,
.game-other-info-registration-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #fff;
}

.game-other-info-registration-area
	.form-inputs-wrapper
	.col-lg-6:nth-child(2n + 1) {
	padding-left: 0;
	padding-right: 5px;
}

.game-other-info-registration-area
	.form-inputs-wrapper
	.col-lg-6:nth-child(2n) {
	padding-right: 0;
	padding-left: 5px;
}

.sign-in-fullpage-area .form-group-input-wrapper .field-icon,
.game-other-info-registration-area .form-group-input-wrapper .field-icon {
	color: #fff;
}

.sign-in-fullpage-area
	.form-group-input-wrapper
	.form-group-input-password-wrapper,
.game-other-info-registration-area
	.form-group-input-wrapper
	.form-group-input-password-wrapper {
	position: relative;
}

.game-other-info-registration-area
	.ngx-select.dropdown
	.ngx-select__toggle.btn.form-control {
	border: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.game-other-info-registration-area
	.ngx-select.dropdown.open.show
	.ngx-select__toggle.btn.form-control {
	border: 1px solid #fff !important;
}

.game-other-info-registration-area .ngx-select__toggle.btn.form-control,
.game-other-info-registration-area
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control {
	font-size: 13px !important;
	padding: 3px 7px !important;
	height: 30px;
}

.game-other-info-registration-area .ngx-select__toggle.btn.form-control:hover {
	background-color: transparent !important;
}

.game-other-info-registration-area .ngx-select__placeholder.text-muted {
	color: #fff !important;
}

.game-other-info-registration-area .rs-other-info.termes-policy a,
.game-other-info-registration-area .rs-other-info .rs-link,
.game-other-info-registration-area .rs-other-info .rs-link a {
	color: $tertiary-color;
}

.game-other-info-registration-area .rs-other-info.termes-policy a:hover,
.game-other-info-registration-area .rs-other-info .rs-link:hover,
.game-other-info-registration-area .rs-other-info .rs-link a:hover {
	color: darken($tertiary-color, 10%);
}

.game-other-info-registration-area .rs-submit input[type="submit"],
.rs-submit button {
	background: #face08;
	color: #19674b;
	font-size: 14px;
	font-weight: 700;
	border-radius: 8px;
}

.game-other-info-registration-area .rs-submit input[type="submit"]:hover,
.rs-submit button:hover {
	background: lighten(#face08, 10%);
	color: #19674b;
}

.rs-other-info {
	margin-top: 20px;
}

.rs-other-info .rs-link {
	color: #face08;
}

.game-other-info-box-area .col-lg-2 {
	flex: 0 0 21%;
	max-width: 21%;
}

.game-other-info-box-area .col-lg-4 {
	flex: 0 0 29%;
	max-width: 29%;
}

.game-other-info-box-area .col-lg-3 {
	flex: 0 0 30%;
	max-width: 30%;
}

.game-other-info-box-area .col-lg-9 {
	flex: 0 0 70%;
	max-width: 70%;
}

.game-other-info-box-area .col-lg-6 {
	flex: 0 0 50%;
	max-width: 50%;
	display: flex;
}

.cricket-carousel-logged-in,
.football-carousel-logged-in {
	width: 50%;
}

.cricket-carousel-logged-in {
	padding-right: 3px;
}

.football-carousel-logged-in {
	padding-left: 3px;
}

.banner-slider-carousel {
	border: 1px solid #38a180;
}

.cricket-match-carousel-area,
.football-match-carousel-area {
	position: relative;
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	background-color: #fff;
	z-index: 1;
	border: 1px solid $primary-color-shade-4;
	color: $fourth-color-shade-1;
	height: 160px;
}

.cricket-carousel-logged-in .cricket-match-carousel-area,
.football-carousel-logged-in .football-match-carousel-area {
	height: 325px;
	margin-bottom: 0;
}

.cricket-match-carousel-area {
	margin-bottom: 5px;
}

.cricket-match-carousel-area:before,
.football-match-carousel-area:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $primary-color-shade-1;
	content: "";
	z-index: -1;
	opacity: 0.85;
}

.cricket-match-carousel-area.no-live-match:before,
.football-match-carousel-area.no-live-match:before {
	opacity: 0.65;
}

.cricket-match-carousel-area .cricket-match-summary-carousel.owl-carousel,
.cricket-match-carousel-area .cricket-match-summary-details,
.cricket-match-carousel-area
	.cricket-match-summary-details
	.match-details-info
	.team-score {
	background: transparent;
}

.cricket-match-carousel-area .cricket-match-summary {
	align-items: center;
	cursor: pointer;
}

.cricket-match-carousel-area .cricket-match-summary-details {
	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 1;
	justify-content: space-between;
}

.cricket-match-carousel-area .cricket-match-summary-details.pb-30 {
	padding-bottom: 30px;
}

.cricket-match-carousel-area
	.cricket-match-summary-details
	.match-details-info {
	width: 100%;
}

.cricket-match-carousel-area .cricket-match-summary-header {
	display: flex;
	font-size: 11px;
	height: 25px !important;
	width: 100%;
	padding: 0 5px;
}

.cricket-match-carousel-area .cricket-match-summary-header span {
	display: inline-block;
	width: 100%;
	overflow: hidden;
	text-transform: uppercase;
	font-weight: 700;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
}

.cricket-match-carousel-area
	.cricket-match-summary-details
	span.team-name-flag
	.name {
	color: #fff;
	font-family: "ProximaNovaRegular", sans-serif;
	font-size: 10px;
	margin-top: 5px;
	font-weight: 600;
	text-transform: uppercase;
	height: 30px;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: pre-wrap;
}

.cricket-match-carousel-area
	.cricket-match-summary-details
	span.team-name-flag {
	max-width: 45px;
}

.cricket-match-carousel-area
	.cricket-match-summary-details
	span.team-name-flag
	.flag
	img {
	height: 30px;
	max-width: 50px !important;
}

.cricket-carousel-logged-in
	.cricket-match-carousel-area
	.cricket-match-summary-details
	span.team-name-flag
	.flag
	img {
	height: 40px;
}

.cricket-carousel-logged-in
	.cricket-match-carousel-area
	.cricket-match-summary-details
	span.team-name-flag
	.name {
	height: auto;
}

.cricket-match-carousel-area
	.cricket-match-summary-details
	.match-details-info
	.team-score {
	height: 35px;
	font-family: "ProximaNovaRegular", sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.cricket-match-carousel-area
	.cricket-match-summary-details
	.match-details-info
	.team-score
	.bottom.overs
	small {
	margin-left: 2px;
}

.cricket-match-carousel-area .cricket-match-summary-details .match-result {
	font-size: 12px;
	padding: 10px;
	display: block;
	width: 100%;
	align-items: center;
	text-align: center;
	justify-content: center;
	padding-top: 0;
	/* text-transform: capitalize;
    text-overflow: ellipsis;
    overflow: hidden; */
	white-space: nowrap;
	padding-bottom: 5px;
	/* animation: left-right 5s linear infinite alternate; */
}

@keyframes left-right {
	0% {
		transform: translate(90%, 0);
	}

	100% {
		transform: translate(-90%, 0);
	}
}

.cricket-match-carousel-area
	.cricket-match-summary-details
	.match-details-info {
	padding: 13px 15px;
}

.cricket-match-carousel-area span.series-vs-text {
	width: 25px;
	height: 25px;
	background: #15ce05;
	align-items: center;
	justify-content: center;
	color: #053a29;
	font-weight: 700;
	font-size: 14px;
	border-radius: 50%;
	box-shadow: 0 0 0 2px #053a29, 0 0 0 3px #15ce05;
	position: relative;
}

.cricket-match-carousel-area span.series-vs-text:before,
.cricket-match-carousel-area span.series-vs-text:after {
	position: absolute;
	top: -15px;
	left: 50%;
	transform: translateX(-50%);
	height: 8px;
	width: 2px;
	background: #15ce05;
	content: "";
}

.cricket-match-carousel-area span.series-vs-text:after {
	top: auto;
	bottom: -15px;
}

.cricket-match-carousel-area .cricket-match-summary-details span.series-flag {
	height: 70px;
}

.football-match-carousel-area .football-match-summary {
	background: transparent;
	color: #fff;
	width: 100%;
	padding: 0;
}

.football-match-carousel-area .football-match-summary .match-details-info {
	padding: 0 8px;
	width: 100%;
}

.football-match-carousel-area .football-match-summary-carousel.owl-carousel {
	background: transparent;
}

.football-match-carousel-area .football-match-summary .match-date-time {
	text-align: center;
	padding: 0 5px;
	font-size: 11px;
	font-weight: 700;
	margin-bottom: 13px;
	text-transform: uppercase;
	height: 25px;
	background: rgba(5, 58, 41, 0.85);
	display: flex;
	align-items: center;
	justify-content: center;
}

.football-match-carousel-area .football-match-summary .match-date-time span {
	display: inline-block;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
	overflow: hidden;
	height: auto;
}

.football-match-carousel-area
	.football-match-summary
	span.team-name-flag
	.name {
	font-family: "ProximaNovaRegular", sans-serif;
	font-size: 8px;
	text-transform: uppercase;
	font-weight: 600;
	height: 25px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	white-space: pre-wrap;
}

.football-match-carousel-area .football-match-summary:hover {
	color: #fff;
}

.football-match-carousel-area .football-match-summary .won *,
.football-match-carousel-area
	.football-match-summary
	.match-details-info
	.won
	.team-score {
	color: #fff;
}

.football-match-carousel-area
	.football-match-summary
	.match-details-info
	.team-score {
	color: #fff;
}

.football-match-carousel-area
	.football-match-summary
	span.team-name-flag
	.flag
	img {
	max-height: 30px;
	height: 30px;
	width: auto !important;
}

.football-carousel-logged-in
	.football-match-carousel-area
	.football-match-summary
	span.team-name-flag
	.flag
	img {
	max-height: 40px;
	height: 40px;
}

.football-match-carousel-area .football-match-summary .series-name {
	font-size: 12px;
	font-weight: 400;
	text-transform: capitalize;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-top: 12px;
	padding-bottom: 2px;
}

.football-carousel-logged-in
	.football-match-carousel-area
	.football-match-summary
	span.team-name-flag
	.name {
	font-size: 10px;
	height: auto;
	overflow: unset;
}

.cricket-match-carousel-area .cricket-match-summary-carousel,
.cricket-match-carousel-area .cricket-match-summary,
.cricket-match-carousel-area .cricket-match-summary-carousel *,
.football-match-carousel-area .football-match-summary-carousel,
.football-match-carousel-area .football-match-summary,
.football-match-carousel-area .football-match-summary-carousel * {
	height: 100%;
}

.cricket-match-carousel-area .cricket-match-summary *,
.football-match-carousel-area .football-match-summary * {
	height: auto;
}

.football-match-carousel-area .football-match-summary {
	justify-content: space-between;
	padding-bottom: 30px;
}

.cricket-match-carousel-area .cricket-match-summary-carousel .owl-dots,
.football-match-carousel-area .football-match-summary-carousel .owl-dots {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 10px;
	position: absolute;
	bottom: 0;
	height: auto;
}

.cricket-match-carousel-area .cricket-match-summary-carousel .owl-dots button,
.football-match-carousel-area
	.football-match-summary-carousel
	.owl-dots
	button {
	height: 2px !important;
	width: 30px !important;
	display: inline-block !important;
	margin: 0 5px !important;
	transition: 0.2s;
	background: #fff !important;
}

.cricket-match-carousel-area
	.cricket-match-summary-carousel
	.owl-dots
	button.active,
.football-match-carousel-area
	.football-match-summary-carousel
	.owl-dots
	button.active {
	background: #15ce05 !important;
}

.responsive-main-menu button[aria-expanded="true"],
.responsive-main-menu a.dropdown-toggle[aria-expanded="true"] {
	color: F4E849;
}

.container-fluid.header-top-area .col {
	padding: 0;
}

.container-fluid.header-top-area .row {
	margin: 0;
}

.admin-template-area {
	// min-height: calc(100vh - 96px) !important;
}

section.admin-template-area .left-sidebar {
	padding-right: 2px !important;
}

.admin-profile-update-form .form-group .ngx-select__toggle.btn.form-control {
	border-color: #ddd !important;
	padding-top: 2px !important;
	height: 40px;
}

.admin-profile-update-form
	.form-group
	.ngx-select__toggle.btn.form-control:hover {
	background: #f9f9f9 !important;
}


.language-list .btn {
	justify-content: center;
}

.no-cricket-match-live,
.no-football-match-live {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	font-size: 16px;
	text-transform: capitalize;
	padding: 16px;
}

.no-cricket-match-live-content h3,
.no-football-match-live-content h3 {
	font-size: 18px;
	margin-bottom: 6px;
}

.no-cricket-match-live-content a,
.no-football-match-live-content a {
	color: $fourth-color-shade-1;
	font-size: 12px;
	font-weight: 600;
}

.no-cricket-match-live-content a i.zmdi,
.no-football-match-live-content a i.zmdi {
	margin-left: 7px;
}

.sport-card-accordion {
	background: #22815e;
	// box-shadow: 0 0 18px rgb(52 52 52 / 42%);
	// padding: 7px;
	padding-top: 0;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
}

.sport-card-accordion-header {
	color: #fff;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 13px;
	padding: 7px 10px;
}

.collaps-active .collapse-d-none {
	display: none;
}
.sport-card-level-one
	.sport-card-level-one-header
	.sport-card-level-one-header-link.active {
	color: #212529;
}

.sport-card-level-one
	.sport-card-level-one-header:hover
	.sport-card-level-one-header-link.active {
	color: #fff;
}

.favourite-card-header .number {
	margin: 0;
	width: 25px;
	height: 25px;
	background: #15ce05;
	color: #053a29;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	font-weight: 700;
}

.favourite-card-header {
	padding: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.collaps-active .favourite-card-header .number {
	position: absolute;
	right: 5px;
	top: 5px;
	width: 20px;
	height: 20px;
	font-size: 11px;
}

.single-match-result-box .match-name {
	width: 65%;
}

.single-match-result-box .match-point {
	height: 33px;
	align-items: center;
	justify-content: center;
	width: 30%;
	background: #00060c4d;
	font-weight: 700;
}

.single-match-result-box {
	padding-right: 0;
}

.match-details-breadcumb-area {
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: auto;
	padding: 7px;
	font-size: 13px;
}

.match-details-breadcumb-area ul {
	display: flex;
	align-items: center;
}

.match-details-breadcumb-area ul.statistics-link {
	width: 30%;
	justify-content: flex-end;
}

.match-details-breadcumb-area ul.breadcumb {
	width: 70%;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-wrap: wrap;
}

.match-details-breadcumb-area ul li {
	display: inline-block;
}

.match-details-breadcumb-area ul li a {
	display: flex;
	color: #fff;
	transition: 0.3s;
	height: 20px;
	align-items: center;
	margin: 0 5px;
	font-size: 13px;
	position: relative;
	white-space: nowrap;
}

.match-details-breadcumb-area ul.statistics-link li a {
	width: 20px;
}

.match-details-breadcumb-area ul li a:hover {
	color: $tertiary-color;
}

.match-details-breadcumb-area ul li a i.fas {
	font-size: 20px;
}

.match-details-breadcumb-area ul li a img {
	height: 25px;
}

.match-details-breadcumb-area ul.breadcumb li a {
	margin-right: 20px;
}

.match-details-breadcumb-area ul.breadcumb li a:before {
	position: absolute;
	right: -15px;
	top: 50%;
	transform: translateY(-50%);
	content: "/";
	color: #fff;
	font-weight: 700;
}

.match-details-breadcumb-area ul.breadcumb li:last-child a:before {
	display: none;
}

.match-details-banner {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	color: #fff;
	border-radius: 5px;
	overflow: hidden;
	padding-top: 5px;
	margin-bottom: 5px;
}

.match-details-banner:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #19674b;
	background: -moz-linear-gradient(top, #19674b 0%, #22815e 50%, #19674b 100%);
	background: -webkit-linear-gradient(
		top,
		#19674b 0%,
		#22815e 50%,
		#19674b 100%
	);
	background: linear-gradient(to bottom, #19674b 0%, #22815e 50%, #19674b 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19674b', endColorstr='#22815e',GradientType=0 );

	content: "";
	z-index: -1;
	opacity: 0.85;
}

.match-countdown-timer {
	height: 30px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 14px;
}

.match-countdown-timer .icon {
	margin-right: 10px;
	font-size: 14px;
}

.match-countdown-timer .timer span,
.match-countdown-timer .timer sup {
	margin-right: 5px;
	text-transform: uppercase;
	font-weight: 600;
}

.match-countdown-timer .timer sup {
	font-size: 8px;
}

.match-countdown-timer .timer span.active,
.match-countdown-timer .timer sup.active {
	color: #15ce05;
}

.match-location-date {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 11px;
	height: 20px;
}

.match-location-date .icon {
	margin-left: 10px;
}

.match-location-date .icon img {
	height: 15px;
}

.match-team-list {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	text-align: center;
	padding: 20px;
}

.match-team-list .team-name-flag .flag img {
	height: 40px;
	box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.4);
	border-radius: 1px;
}

.team-name-flag {
	display: flex;
	flex-direction: column;
}

.team-name-flag a {
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	margin-top: 8px;
	display: inline-block;
	transition: 0.3s;
}

.match-start-time {
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	font-size: 12px;
	color: #efff00;
}

.match-start-time .time {
	font-size: 30px;
	line-height: 30px;
}

.team-name-flag a:hover {
	color: #15ce05;
}

.match-weather-forecast {
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	font-size: 11px;
	justify-content: space-between;
	padding: 8px 20px;
	align-items: center;
}

.match-weather-forecast ul {
	display: flex;
	align-items: center;
}

.match-weather-forecast ul li {
	margin-right: 20px;
}

.match-weather-forecast ul li:last-child {
	margin-right: 0;
}

.match-weather-forecast .icon {
	margin-right: 7px;
}

.match-weather-forecast i.fas.rotate-180 {
	transform: rotate(180deg);
}

.game-options-area {
	margin: 0 -5px;
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 40px;
}

.game-options-left-side,
.game-options-right-side {
	width: 50%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	border-radius: 5px;
	padding: 0 5px;
}

.bets-number {
	background: #0b3c2c;
	color: #fff;
	font-size: 12px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 3px 10px;
}

.game-option-list-search {
	display: flex;
	overflow: hidden;
	border-radius: 5px;
	background-color: #19674b;
}

.bets-number .icon {
	margin-right: 6px;
}

.game-search-area input:not([type="submit"]) {
	border-radius: 5px;
	color: #fff;
	position: relative;
	padding: 5px;
	padding-left: 40px;
	height: 40px;
	font-size: 13px;
	border: none;
	overflow: hidden;
	background-color: #19674b;
}

.game-search-area input[type="submit"],
.game-search-area button[type="submit"] {
	position: absolute;
	left: 0;
	top: 0;
	width: 40px;
	height: 40px;
	background: transparent;
	border: none;
	color: #face08;
	cursor: pointer;
}

.game-search-area {
	position: relative;
}

.game-search-area input:not([type="submit"]):focus {
	box-shadow: 0 0 0 0;
}

.game-search-area input:not([type="submit"])::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #fff;
}

.game-search-area input:not([type="submit"])::-moz-placeholder {
	/* Firefox 19+ */
	color: #fff;
}

.game-search-area input:not([type="submit"]):-ms-input-placeholder {
	/* IE 10+ */
	color: #fff;
}

.game-search-area input:not([type="submit"]):-moz-placeholder {
	/* Firefox 18- */
	color: #fff;
}

.game-option-list .ngx-select__toggle.btn.form-control,
.game-option-list .ngx-select.dropdown .ngx-select__search.form-control,
.game-option-list
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control {
	padding-top: 2px !important;
	border: none !important;
}

.game-option-list .ngx-select__toggle.btn.form-control {
	background: #0b3c2c !important;
	color: #fff !important;
	font-size: 13px !important;
}

.game-option-list
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control {
	background: #fff !important;
	color: #333 !important;
}

.game-option-list
	.ngx-select__toggle.btn.form-control
	span.ngx-select__placeholder.text-muted {
	color: #fff !important;
	display: flex;
	align-items: center;
}

.game-bets-view-modes ul li {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;
	cursor: pointer;
	transition: 0.3s;
}

.game-bets-view-modes ul {
	display: flex;
	align-items: center;
}

.game-bets-view-modes ul li img {
	height: 25px;
}

.game-bets-view-modes ul li.active:before,
.game-bets-view-modes ul li:hover:before {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background: #15ce05;
	content: "";
	transition: 0.3s;
}

.game-bets-view-modes ul li.active {
	background: #0b3c2c;
}

.match-details-game-tab-area .tab-content.match-details-game-tab .row {
	margin-left: -2px;
	margin-right: -2px;
}

.match-details-game-tab-area
	.tab-content.match-details-game-tab
	.row
	[class*="col-lg-"] {
	padding-left: 2px;
	padding-right: 2px;
}

.match-details-game-tab-area .single-match-result-accordion-body .row {
	margin: 0 !important;
}

.match-details-game-tab-area .single-match-result {
	/* margin-bottom: 5px; */
	border-bottom: 1px solid $primary-color;
}

.betslip-item-list-v2 .single-betlist-item {
	position: relative;
	margin-bottom: 5px;
	border-radius: 5px;
	overflow: hidden;
	background: #fff;
}

.betslip-item-list-v2 span.match-title-image .icon img {
	height: 15px;
}

.betslip-item-list-v2 span.match-title-image .icon {
	margin-right: 10px;
}

.betslip-item-list-v2 span.match-title-image {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 10px;
}

.betslip-item-list-v2 span.betlist-text {
	color: #111;
}

.betslip-item-list-v2 span.match-title-image .match-title:not(.fw-400) {
	font-weight: 700;
}

.betslip-item-list-v2 span.single-result-item {
	padding: 0 10px;
	text-transform: uppercase;
}

.betslip-item-list-v2 span.single-result-item .left-side {
	width: 87%;
}

.betslip-item-list-v2 .betlist-single-item-remove-icon {
	margin-left: 0;
	position: absolute;
	right: 3px;
	top: 5px;
	z-index: 10;
	color: #000;
	background: transparent;
	border: none;
}

.input-number-wrapper {
	position: relative;
	display: flex;
}

.input-number-wrapper .input-number.minus,
.input-number-wrapper .input-number.plus {
	width: 24px;
	height: 24px;
	display: flex;
	background: #ddd;
	color: #000;
	font-weight: 700;
	font-size: 14px;
	align-items: center;
	justify-content: center;
	text-align: center;
	transition: 0.3s;
	cursor: pointer;
}

.betslip-bid-area ul li .input-number-wrapper input[type="text"],
.betslip-bid-area ul li .input-number-wrapper input[type="number"] {
	text-align: center;
}

.input-number-wrapper .input-number.plus:hover {
	background: #eee;
	color: #000;
}

.input-number-wrapper .input-number.minus:hover {
	background: #eee;
	color: #000;
}

.logo img {
	max-height: 37px;
}

a.logged-in-user span.user-balance {
	margin-left: 0;
	text-align: center;
	justify-content: center;
	align-items: center;
}

.main-balance {
	margin-bottom: 0;
}

.admin-profile-info-icon {
	font-size: 18px;
	opacity: 1;
	margin-top: 2px;
	background: #19674b;
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}
.admin-profile-username {
	font-size: 14px;
	text-transform: capitalize;
	margin-bottom: 6px;
}

.betslip-bid-area ul li .possible-win {
	font-weight: 700;
}

.message-preloader-area {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	z-index: 10000;
	height: 100%;
	color: #555;
	font-size: 20px;
}

.preloader-message {
	padding: 40px 20px;
	border: 4px double #555;
	background: rgba(243, 243, 243, 0.74);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.preloader-message .icon img {
	height: 100px;
}

.preloader-message .message .large {
	font-size: 45px;
	font-weight: 600;
	margin: 15px 0;
	line-height: 45px;
}

.preloader-message .message .small {
	font-size: 22px;
	padding: 10px;
	max-width: 80%;
	margin: 0 auto;
}

.preloader-message a.try-button {
	font-size: 17px;
	color: #0e4835;
	display: inline-block;
	padding: 8px 30px;
	border: 2px solid #0e4835;
	margin-top: 30px;
	transition: 0.3s;
	border-radius: 5px;
	cursor: pointer;
	font-weight: 600;
}

.preloader-message a.try-button:hover {
	color: #fff;
	background: #0e4835;
}

.collaps-active .admin-left-sidebar-toggle-btn-wrapper {
	margin-bottom: 0;
}

.admin-menu-list-area.profile.active {
	top: 147px;
}

.admin-menu-list-area.wallet.active {
	top: 196px;
}

.admin-menu-list-area.help.active {
	top: 245px;
}

/* ng-http-loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
} */
ng-http-loader #spinner.backdrop {
	position: absolute;
}

.online {
	background-color: green;
	color: #ffffff;
	padding: 10px;
	text-align: center;
	animation: online-response 0.5s 1;
	-webkit-animation: online-response 0.5s 1;
	animation-fill-mode: forwards;
	animation-delay: 2s;
	-webkit-animation-delay: 1s;
	/* Safari and Chrome */
	-webkit-animation-fill-mode: forwards;
	position: fixed;
	top: 10px;
	right: 10px;
	width: 10%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 15000;
}

@keyframes online-response {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes online-response {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.admin-content .tab-content {
	border: none;
	padding: 0;
}

.admin-content-header {
	margin-bottom: 20px;
	text-transform: uppercase;
	font-weight: 600;
	padding-bottom: 7px;
	border-bottom: 1px solid #e2e2e2;
	position: relative;
}

.admin-content-header:before {
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 15%;
	height: 1px;
	background: #14805e;
	content: "";
}

.admin-profile-table .col-lg-6 {
	padding: 0;
}

.admin-profile-table .col-lg-6:nth-child(2n) {
	padding-left: 6px;
}

.admin-profile-table .col-lg-6:nth-child(2n + 1) {
	padding-right: 6px;
}

.admin-profile-table-v-2 table tr td {
	width: 50%;
}

.admin-profile-update-form span.ngx-select__placeholder.text-muted {
	align-items: center;
	display: flex;
}

.admin-profile-update-form
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control {
	border: 1px solid $primary-color-shade-3 !important;
	background: $primary-color-shade-3;
	padding-top: 2px !important;
	color: #fff !important;
	height: 40px;
}

.admin-profile-update-form
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #fff;
	opacity: 1;
	/* Firefox */
}

.admin-profile-update-form
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
}

.admin-profile-update-form
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #fff;
}

.sport-card-level-one .sport-card-level-one-header-link {
	justify-content: space-between;
}

.sport-card-level-one
	.sport-card-level-one-header-link
	.category-number
	.number {
	justify-content: center;
	margin: 0;
	width: 20px;
	height: 20px;
	background: #19674b;
	color: #fff;
	font-size: 11px;
	border-radius: 50%;
	transition: 0.3s;
	position: relative;
	z-index: 100;
}

.game-other-info-registration-area.registration-fullpage-area {
	border: none;
	padding: 1%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 97px);
}

.registration-fullpage-tab-box {
	border: 1px solid $secondary-color;
	position: relative;
}

.registration-fullpage-tab-box .tab-content {
	padding: 15px;
}

.registration-fullpage-tab-box .tab-content .row.form-inputs-wrapper {
	margin-left: 0;
	margin-right: 0;
}

.registration-fullpage-tab-box .nav {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $secondary-color;
}

.registration-fullpage-tab-box .nav-tabs .nav-item {
	text-align: center;
	width: 50%;
}

.registration-fullpage-tab-box .nav-tabs .nav-item .nav-link {
	background: transparent;
	color: #fff;
	border-radius: 0;
	text-transform: uppercase;
	font-weight: 600;
	border: none;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.registration-fullpage-tab-box .nav-tabs .nav-item .nav-link.active {
	color: $tertiary-color;
}

.registration-fullpage-tab-box:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: $primary-color;
	content: "";
	z-index: -1;
	opacity: 1;
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control,
.game-other-info-registration-area.registration-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control,
.game-other-info-registration-area.registration-fullpage-area
	.ngx-select__toggle.btn.form-control,
.game-other-info-registration-area.registration-fullpage-area
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control {
	height: 35px;
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:focus,
.game-other-info-registration-area.registration-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:focus {
	color: #333;
	background: #fff;
}

.game-other-info-registration-area.registration-fullpage-area .rs-other-info {
	margin-top: 5px;
}

.registration-fullpage-tab-box .col-lg-12 {
	padding-left: 0;
	padding-right: 0;
}

.registration-fullpage-tab-box .form-check-input {
	position: absolute;
	margin-top: 2px;
	margin-left: 0px;
}

.registration-fullpage-tab-box .form-check-label {
	padding-left: 20px;
}

.game-other-info-registration-area.registration-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control:focus
	+ .field-icon {
	color: #666;
}

.game-other-info-registration-area .ngx-select__placeholder.text-muted {
	display: flex;
	align-items: center;
}

.game-other-info-registration-area.registration-fullpage-area
	.fp-instruction-text {
	font-size: 15px;
	margin-bottom: 18px;
	text-align: center;
}

input[type="submit"]:disabled,
button[type="submit"]:disabled {
	cursor: not-allowed;
}

.game-other-info-registration-area.registration-fullpage-area.mt-100 {
	background-position: bottom;
}


.event-type-list {
	padding: 0;
}

.nav.match-details-game-tab-2 .single-event-type-list-item {
	border: 0;
	padding: 7px;
	background: #38a180;
}

.nav.match-details-game-tab-2 .single-event-type-list-item.active,
.nav.match-details-game-tab-2 .single-event-type-list-item:hover {
	background: #116d50;
	color: #fff;
}

.admin-profile-table .table.table thead th {
	font-weight: 400;
}

.admin-template-area .row.justify-content-left [class*="col-lg-"] {
	padding-left: 0;
	padding-right: 0;
}

.admin-content-header-new {
	color: #fff;
	text-transform: uppercase;
	padding: 10px 12px;
	padding-top: 14px;
}

.admin-content .admin-content-scroll-wrapper {
	padding: 15px;
	overflow: hidden;
	// overflow-y: auto;
	// height: calc(100% - 64px);
	position: relative;
	background: #fff;
	border-radius: 0 0 5px 5px;
}

.admin-content .tab-content {
	overflow-y: hidden;
}

.admin-content .tab-content .tab-pane {
	height: 100% !important;
}

.admin-profile-update-form .form-group {
	margin-bottom: 0;
	margin-top: 25px;
}

.admin-profile-update-form .form-group label {
	margin-bottom: 0;
	height: 40px;
	display: flex;
	align-items: center;
	font-size: 15px;
}

.admin-profile-update-form .form-group .form-group-input-wrapper {
	display: flex;
	align-items: center;
}

.admin-profile-update-form .form-group .input-group-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 42px;
	height: 40px;
	background: #dbdbdb;
	background: linear-gradient(to bottom, #e7e7e7 0%, #dbdbdb 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#e7e7e7", endColorstr="#dbdbdb",GradientType=0 );
	color: #000;
	border: 1px solid #9f9f9f;
	border-right: none;
	border-radius: 6px 0 0 6px;
}

.admin-profile-update-form .form-group ngx-select,
.admin-profile-update-form .form-group .form-control {
	width: calc(100% - 42px);
}

.admin-profile-update-form
	.ngx-select.dropdown.open.show
	.ngx-select__search.form-control,
.admin-profile-update-form .form-group .ngx-select__toggle.btn.form-control,
.admin-profile-update-form .form-group .form-control.form-control-100 {
	width: 100%;
}

.admin-profile-update-form .form-group .form-control {
	height: 40px;
}

.admin-profile-condition .icon {
	border-top-right-radius: 0;
}

.admin-profile-condition {
	margin-bottom: 8px;
	margin-top: 20px;
}

.live-in-play-header {
	font-family: "ProximaNovaRegular", sans-serif;
}

.single-live-events-in-mobile {
	margin-right: 0;
	width: 100%;
}

.admin-content.collaps-active {
	-ms-flex: 0 0 calc(100% - 70px);
	flex: 0 0 calc(100% - 70px);
	max-width: calc(100% - 70px);
}

.admin-left-sidebar-toggle-btn-wrapper .icon {
	width: 22px;
}

.admin-left-sidebar-toggle-btn-wrapper .icon img,
.left-sidebar-toggle-btn-area img {
	height: 18px;
}

.admin-profile-sidebar-menu-area {
	// margin-top: 5px;
	padding: 20px;
	color: #fff;
}

.admin-profile-sidebar-menu-group h4,
.admin-profile-sidebar-menu-group .h4 {
	color: rgb(25 103 75);
	text-transform: uppercase;
	font-weight: 600;
	font-size: 11px;
	margin-bottom: 20px;
}

.admin-profile-sidebar-menu-list ul li a {
	color: #222;
	display: flex;
	width: 100%;
	align-items: center;
	transition: 0.3s;
	margin-bottom: 10px;
	margin-bottom: 16px;
	font-size: 15px;
}
.admin-profile-sidebar-menu-list ul li:last-child a {
	margin-bottom: 0;
}

.admin-profile-sidebar-menu-list ul li a:hover,
.admin-profile-sidebar-menu-list ul li a.active {
	color: #000;
	font-weight: 500;
}

.admin-profile-sidebar-menu-list ul li a .icon {
	margin-right: 12px;
	width: 20px;
	text-align: center;
	opacity: 0.6;
}

.admin-profile-sidebar-menu-group {
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	padding-bottom: 15px;
	margin-bottom: 15px;
}

.admin-profile-sidebar-menu-group:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.collaps-active .admin-profile-sidebar-menu-list ul li a .icon {
	margin-right: 0;
}

.collaps-active .admin-profile-sidebar-menu-area {
	margin-top: 5px;
	padding: 10px;
}

.collaps-active .admin-profile-sidebar-menu-list ul li a {
	margin-bottom: 0;
	width: 32px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.swal2-container {
	z-index: 10060 !important;
}

.swal2-styled {
	background-color: #14805e !important;
	transition: 0.3s;
}

.responsive-menu-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.responsive-menu-wrapper .icon-user-menu {
	display: none;
	margin-right: 10px;
}

.responsive-menu-wrapper .icon-user-menu img {
	height: 18px;
}

.sign-up-button-mobile {
	display: none !important;
}

.sign-in-off-canvas-area {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10000;
	width: 100%;
	height: 100%;
	transition: 0.5s;
	opacity: 0;
	visibility: hidden;
}

.sign-in-off-canvas {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) scale(0);
	width: 260px;
	padding: 10px;
	height: auto;
	transition: 0.5s;
}

.sign-in-off-canvas-area-close {
	position: absolute;
	left: -40px;
	top: 0;
	width: 40px;
	height: 40px;
	background: #15ce05;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	color: #052d20;
	transition: 0.5s;
}

.active .sign-in-off-canvas {
	transform: translate(-50%, -50%) scale(1);
}

.sign-in-off-canvas-area-overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.5;
	visibility: hidden;
	transition: 0.5s;
}

.sign-in-off-canvas-area.active {
	opacity: 1;
	visibility: visible;
}

.active .sign-in-off-canvas-area-overlay {
	opacity: 0.5;
	visibility: visible;
}

.sign-in-off-canvas-header {
	text-transform: uppercase;
	border-bottom: 1px solid #fff;
	margin-bottom: 10px;
	padding-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sign-in-off-canvas-header span {
	color: #fff;
	text-transform: uppercase;
	font-size: 17px;
}

.sign-in-off-canvas-header span.icon-close {
	color: #15ce05;
	font-size: 17px;
}

.full-height {
	height: 100% !important;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.overflow-y-auto {
	overflow-y: auto !important;
}

.p-0 {
	padding: 0 !important;
}

.sign-in-off-canvas.full-height {
	background: #14805e;
}

.off-canvas-user-info-area {
	background: #04271c;
	color: #fff;
	padding: 20px;
}

.off-canvas-user-info-area .welcome-message {
	text-transform: uppercase;
	font-size: 10px;
	color: #15ce05;
}

.off-canvas-user-info-area .user-profile-username {
	margin-bottom: 15px;
	text-transform: uppercase;
	font-weight: 700;
}

.off-canvas-user-info-area .user-main-balance {
	display: flex;
	font-size: 13px;
	text-transform: capitalize;
	justify-content: space-between;
}

.user-top-up-link a {
	display: flex;
	height: 30px;
	background: #15ce05;
	color: #052d20 !important;
	text-align: center;
	margin-top: 10px;
	width: 100%;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	align-items: center;
	justify-content: center;
}

.user-logout a {
	display: flex;
	height: 30px;
	background: #fff;
	color: #052d20 !important;
	margin-top: 6px;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.loading-text,
.spinner {
	z-index: 10001 !important;
}

.after-login-dropdown-menu .nav a:first-child {
	border-top: none !important;
}

.dropdown-menu {
	z-index: 3000;
}

a.logged-in-user span.user-balance span {
	text-transform: uppercase !important;
}

.off-canvas-user-info-area .user-main-balance .balance {
	color: #15ce05;
}

.betslip-card-close {
	color: #15ce05;
	width: 22px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: 0.5s;
}

.main-balance p {
	color: #fff;
}

.cricket-match-summary-details .match-details-info .right-side span.top {
	text-align: right;
}

.cricket-match-summary-details .match-details-info span.left-side,
.cricket-match-summary-details .match-details-info span.right-side {
	width: 50%;
}

.cricket-match-summary-details .match-details-info span.right-side {
	justify-content: flex-end;
}

.cricket-match-summary-details .match-details-info span.left-side {
	justify-content: flex-start;
}

.betslip-item-list-v2 span.match-title-image {
	padding: 0 10px;
}

.betslip-item-list-v2 span.match-title-image.colored-title {
	background: #fff;
	color: #000;
	padding: 6px 10px;
	margin-bottom: 0;
}

.betslip-item-list-v2 .single-betlist-item.colored-header .single-result-item {
	align-items: flex-start;
}

.betslip-item-list-v2
	.single-betlist-item.colored-header
	span.single-result-item
	.left-side {
	padding: 6px 10px;
	display: flex;
	color: #777;
	background: #fff;
}

.betslip-item-list-v2
	.single-betlist-item.colored-header
	span.single-result-item
	.right-side {
	background: rgb(24 92 70);
	color: #d7ebff;
	padding: 3px 10px;
	flex: 1 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	border-radius: 5px;
}

.match-details-game-tab-area
	.tab-content.match-details-game-tab
	.row.one-column-mode
	.col-lg-6 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

app-banner-slider {
	width: 100%;
}

.place-bet-btn,
.accept-changes-btn {
	font-family: "ProximaNovaRegular", sans-serif;
	letter-spacing: 0;
	text-transform: uppercase;
	font-size: 14px;
}

.upcomming-matches-area {
	margin-top: 10px;
	background: #22815e;
}

.upcomming-matches-section-title {
	background: #22815e;
	color: $fourth-color-shade-1;
	font-size: 14px;
	padding: 7px 10px;
}

.upcomming-match-details-link {
	display: flex;
	width: 100%;
	flex-direction: column;
}

.upcomming-matches-list-wrapper {
	padding: 0;
	padding-top: 0;
}
.upcomming-matches-list {
	padding: 6px;
	max-height: 1000px;
	/* overflow: hidden; */
	overflow-y: auto;
	background: #fff;
	border-radius: 6px;
}

.single-upcomming-match-card {
	background: #d4e8e1;
	margin-bottom: 5px;
	display: flex;
	transition: 0.3s;
	border-radius: 5px;
	overflow: hidden;
	padding: 5px;
}

.single-upcomming-match-card:hover {
	opacity: 0.95;
}

.single-upcomming-match-card:last-child {
	margin-bottom: 0;
}

.upcomming-match-date {
	background: #19674b;
	color: #face08;
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 7px;
	width: 20%;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 1px solid rgb(20 94 68);
}

.upcomming-match-date .date {
	font-size: 35px;
	font-weight: 700;
	line-height: 35px;
}

.upcomming-match-date .month {
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 16px;
	letter-spacing: 1.4px;
}

.upcomming-match-details {
	display: flex;
	flex-direction: column;
	padding: 5px 10px;
	justify-content: center;
	width: 80%;
	padding-bottom: 0;
}

.upcomming-match-tournament {
	color: #19674b;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 600;
}

.upcomming-match-teams {
	font-weight: 700;
	color: #111;
	margin: 2px 0;
}

.upcomming-match-day-time {
	font-size: 12px;
	font-weight: 600;
	color: #555;
	margin-bottom: 6px;
}

.game-other-info-box-area .row.form-inputs-wrapper .col-lg-12 {
	padding: 0;
}

.game-other-info-box-area .row.form-inputs-wrapper .col-lg-12 {
	padding: 0;
}

.game-other-info-registration-wrapper .nav {
	display: flex;
	justify-content: space-between;
	padding-bottom: 5px;
	margin-bottom: 10px;
}

.game-other-info-registration-wrapper .nav li {
	width: 50%;
	text-align: center;
}

.game-other-info-registration-wrapper .nav li a {
	background: rgba(20, 128, 94, 0.58);
	color: rgba(255, 255, 255, 0.86);
	font-weight: 700;
	text-transform: capitalize;
	font-size: 12px;
	border-radius: 0;
	border: none;
}

.game-other-info-registration-wrapper .nav li a.active {
	color: $secondary-color-shade-1;
	background: $primary-color-shade-3;
}

.registration-fullpage-tab-box .nav-tabs .nav-item:nth-child(1) {
	border-right: 1px solid $secondary-color;
}

.upcomming-match-bet-lists-header {
	color: #14805e;
	font-weight: 700;
	font-size: 13px;
	text-transform: uppercase;
	margin: 8px 0 8px;
	display: block;
	text-decoration: underline;
}

a.logged-in-user {
	position: relative;
	min-width: 135px;
}

span.dropdown-icon {
	font-size: 20px;
	background: rgb(19 24 33 / 41%);
	height: calc(100% - 2px);
	align-items: center;
	width: 25px;
	justify-content: center;
	position: absolute;
	right: 0;
}

.notice-bar-area {
	position: fixed;
	width: 100%;
	height: 30px;
	background: #e4423c;
	color: #fff;
	z-index: 2001;
	overflow: hidden;
	display: flex;
	justify-content: center;
	top: 0;
}

.notice-lists {
	display: flex;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding: 0 10px;
	padding-left: 14px;
}

.notice-lists ul {
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	align-items: center;
	width: 100%;
}


.notice-lists ul li {
	flex-wrap: nowrap;
	white-space: nowrap;
	font-size: 15px;
	font-weight: 600;
	display: inline-block;
	margin-right: 60px;
	position: relative;
}

.notice-lists ul li strong {
	margin: 0 5px;
}

.notice-lists ul li:before {
	position: absolute;
	right: -35px;
	top: 50%;
	transform: translateY(-50%);
	content: "|";
	color: #fff;
}

.notice-lists ul li:last-child {
	margin-right: 0;
}

.notice-lists ul li:last-child:before {
	display: none;
}

.notice-text {
	background: #bb332e;
	color: #fff;
	position: relative;
	left: 0;
	padding: 0 20px;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 1;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 700;
}


.notice-text:before {
	position: absolute;
	right: -12px;
	top: 0;
	width: 0;
	height: 0;
	border-left: 12px solid #bb332e;
	border-top: 15px solid transparent;
	content: "";
	border-bottom: 15px solid transparent;
}


.notice-lists ul {
	position: relative;
	width: 100%;
	height: 100%;
	margin: 0;
	text-align: center;
	/* Starting position */
	-moz-transform: translateX(100%);
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
	/* Apply animation to this element */
	-moz-animation: example1 40s linear infinite;
	-webkit-animation: example1 40s linear infinite;
	animation: example1 40s linear infinite;
}

/* Move it (define the animation) */

@-moz-keyframes example1 {
	0% {
		-moz-transform: translateX(100%);
	}

	100% {
		-moz-transform: translateX(-100%);
	}
}

@-webkit-keyframes example1 {
	0% {
		-webkit-transform: translateX(100%);
	}

	100% {
		-webkit-transform: translateX(-100%);
	}
}

@keyframes example1 {
	0% {
		-moz-transform: translateX(100%);
		/* Firefox bug fix */
		-webkit-transform: translateX(100%);
		/* Firefox bug fix */
		transform: translateX(100%);
	}

	100% {
		-moz-transform: translateX(-100%);
		/* Firefox bug fix */
		-webkit-transform: translateX(-100%);
		/* Firefox bug fix */
		transform: translateX(-100%);
	}
}

.notice-lists:hover ul {
	-moz-animation-play-state: paused;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.form-control {
	border-radius: 0;
}

.has-notice-bar .header-area.fixed {
	top: 30px;
}

.has-notice-bar .page-area {
	margin-top: 87px;
	min-height: calc(100vh - 444px);
}

.mt-100 {
	margin-top: 97px;
}

.preloader-message .icon {
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 100px;
	color: #999;
}

.preloader-message .icon img {
	height: 100px;
	width: 100px;
	display: flex;
}

a.logged-in-user.top-up-area {
	justify-content: center;
	padding: 0 !important;
}

a.logged-in-user.top-up-area span.user-balance {
	width: 100%;
	justify-content: space-between;
	height: 38px;
}

a.logged-in-user.top-up-area span.user-balance .bottom-balance {
	height: 19px;
	width: 100%;
	justify-content: center;
	align-items: center;
}

a.logged-in-user.top-up-area span.user-balance span.top-up-text {
	background: #e4423c;
	width: 100%;
	justify-content: center;
	height: 18px;
	align-items: center;
}

a.logged-in-user.top-up-area span.user-balance span.top-up-text.h-40 {
	height: 38px;
	background: $secondary-color-shade-1;
	color: $fourth-color-shade-1;
}



h2.home-all-category-heading {
	background: #38a180;
	margin: 0;
	text-align: center;
	color: #15ce05;
	text-transform: uppercase;
	font-size: 16px;
	padding: 10px;
	font-weight: 500;
	margin-top: 5px;
	margin-bottom: -5px;
}

.forgot-by-btn-area {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.forgot-by-btn-area .by-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	border: 1px solid #fff;
	margin: 0 5px;
	height: 40px;
	cursor: pointer;
	transition: 0.3s;
}

.forgot-by-btn-area .by-btn.active {
	background: #15ce05;
	border-color: #15ce05;
	color: #053a29;
	font-weight: 600;
	cursor: default;
}

.form-group-input-wrapper .form-group-phone-wrapper {
	display: flex;
	align-items: center;
	background: #fff;
	border-radius: 5px;
}

.form-group-input-wrapper .form-group-phone-wrapper .phone-code-label {
	background: #fff;
	height: 35px;
	border: 1px solid #fff;
	color: $primary-color;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 15px;
	font-weight: 600;
	position: relative;
	z-index: 1;
	font-size: 13px;
}

.form-group-input-wrapper .form-group-phone-wrapper .phone-code-label.h-30 {
	height: 30px;
	border-right: 1px solid #fff;
}

.sport-card-level-two-header {
	padding: 4px 6px;
	padding-left: 0;
}

ul.sport-card-level-one-body {
	margin: 0px 0px 0px 32px;
	list-style: none;
}

ul.sport-card-level-one-body li {
	position: relative;
}

ul.sport-card-level-one-body li:before {
	position: absolute;
	left: -15px;
	top: 0px;
	content: "";
	display: block;
	border-left: 1px solid #14805e;
	height: 16px;
	border-bottom: 1px solid #14805e;
	width: 10px;
}

ul.sport-card-level-one-body li:after {
	position: absolute;
	left: -15px;
	bottom: -7px;
	content: "";
	display: block;
	border-left: 1px solid #14805e;
	height: 100%;
}

ul.sport-card-level-one-body li.root {
	margin: 0px 0px 0px -20px;
}

ul.sport-card-level-one-body li.root:before {
	display: none;
}

ul.sport-card-level-one-body li.root:after {
	display: none;
}

ul.sport-card-level-one-body li:last-child:after {
	display: none;
}

.single-match-result-header .right-side .top-side p {
	color: $secondary-color-shade-1;
}

.sign-in-fullpage-area {
	// position: fixed;
	// left: 0;
	// top: 0;
	// width: 100%;
	// height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	// z-index: 8000;
	background-size: cover;
	background-position: center bottom;
}

.sign-in-fullpage-area .registration-fullpage-tab-box h3 {
	background: rgba(0, 84, 58, 0.86);
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	color: #15ce05;
	font-size: 17px;
	font-weight: 400;
	border-bottom: 1px solid #38a180;
	margin-bottom: 0;
}

.sign-in-fullpage-area .registration-fullpage-tab-box .login-form {
	padding: 20px;
}

.sign-in-fullpage-area .registration-fullpage-tab-box .login-form .row {
	margin-left: 0;
	margin-right: 0;
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.form-group-input-wrapper
	.form-control {
	height: 40px;
	padding: 0 15px;
}

.sign-in-fullpage-area .form-inputs-wrapper .form-group-input-wrapper {
	margin-bottom: 10px;
}

.sign-in-fullpage-area
	.form-inputs-wrapper
	.col-lg-12:nth-last-child(2)
	.form-group-input-wrapper {
	margin-bottom: 0;
}

.sign-in-fullpage-area
	.form-group-input-wrapper
	.form-control:focus
	+ .field-icon {
	color: #666;
}

.sign-in-fullpage-area .registration-fullpage-tab-box {
	width: 25%;
}

.game-other-info-registration-wrapper .nav.nav-tabs li a.nav-link.disabled {
	color: rgba(255, 255, 255, 0.86);
	background-color: rgba(47, 55, 68, 0.58);
}

.forgot-by-btn-area .by-btn.disabled {
	cursor: default;
	pointer-events: none;
}

.mobile-extra-header-menu-area {
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	display: none;
}

.mobile-extra-header-menu ul {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2px;
}

.mobile-extra-header-menu ul li {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.mobile-extra-header-menu ul li a {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	font-size: 11px;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
	color: #fff;
}

.mobile-extra-header-menu ul li:nth-child(2n) {
	border-right: none;
}

.mobile-extra-header-menu ul li.active {
	background-color: #0d7554;
}

.dropdown-balance-area {
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.dropdown-balance-area .balance-area {
	display: flex;
	align-items: center;
}

.dropdown-balance-area .balance-area span.balance {
	padding: 0;
	margin-right: 20px;
	white-space: nowrap;
}

.dropdown-balance-area .balance-area .balance-refresh-btn {
	color: #15ce05;
	cursor: pointer;
}


.balance-type-area {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.single-balance-type {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 10px 20px;
	border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.single-balance-type:last-child {
	border-right: none;
}

.single-balance-type .header-text {
	font-size: 12px;
	text-transform: capitalize;
	color: $secondary-color-shade-1;
	white-space: nowrap;
}

.single-balance-type .amount-text {
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
	white-space: nowrap;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
	white-space: initial !important;
}

.single-match-result-header .left-side span.icons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
}

.single-match-result-header .left-side span.icons img.header-live-icon {
	margin: 0;
	margin-top: 10px;
	margin-right: 6px;
}

.single-match-result-header .left-side span.match-infos {
	width: calc(100% - 28px);
}

/* NEW LAYOUT CSS END */

/* INITIAL POPUP CSS START */
.initial-popup-area {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 10000000;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-x: hidden;
	overflow-y: auto;
	background: rgba(0, 0, 0, 0.7);
	transition: 0.4s;
	opacity: 0;
	visibility: hidden;
}

.initial-popup-area.active {
	opacity: 1;
	visibility: visible;
}

.initial-popup-backdrop {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background: rgba(255, 0, 0, 0);
	content: "";
	z-index: 1;
	overflow-y: auto;
	bottom: 0;
	right: 0;
}

.initial-popup-modal {
	width: 35%;
	background-color: #fff;
	border-radius: 10px;
	margin: 20px;
	display: flex;
	justify-content: space-between;
	position: relative;
	z-index: 1;
	box-shadow: 0 0 5px #022d20;
	background-size: cover;
	background-position: center;
}

.initial-popup-modal:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #14805e;
	content: "";
	border-radius: 10px;
	z-index: -1;
	opacity: 0.7;
}

.initial-popup-close {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	cursor: pointer;
	/* background: #fff; */
	border-top-right-radius: 10px;
}

/* .initial-popup-left, .initial-popup-right {
    max-width: 20%;
    flex: 0 0 20%;
} */

.initial-popup-content {
	max-width: 100%;
	flex: 0 0 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding: 40px;
}

.initial-popup-content h3 {
	font-family: "ProximaNovaCondensedRegular", sans-serif;
	text-transform: uppercase;
	margin-bottom: 10px;
	font-size: 22px;
	color: #15ce05;
}

.initial-popup-content p {
	font-size: 13px;
	margin-bottom: 10px;
	color: #eee;
}

.initial-popup-content p.terms {
	font-size: 12px;
}

.initial-popup-content p.terms a {
	text-decoration: underline;
	font-weight: 600;
	color: #fff;
}

.initial-popup-content p.terms a:hover {
	color: #15ce05;
}

.initial-popup-content p:last-child {
	margin-bottom: 0;
}

.sign-in-register-area {
	margin: 20px 0 30px;
	min-width: 50%;
}

.sign-in-register-area a {
	display: inline-flex;
	padding: 3px 10px;
	border: 1px solid #14805e;
	border-radius: 5px;
	height: 30px;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	/* width: 80%; */
	white-space: nowrap;
	color: #14805e;
	background: #15ce05;
	font-weight: 700;
}

.sign-in-register-area .register a {
	width: auto;
	padding: 0;
	height: auto;
	border: none;
	color: #15ce05;
	font-weight: 600;
}

.show-no-more-message-form-group {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.show-no-more-message-form-group label {
	margin: 0;
	margin-left: 6px;
	cursor: pointer;
	color: #15ce05;
	font-weight: 600;
}

.sign-in-register-area a:hover {
	background: #fded20;
	border-color: #0c5f45;
	color: #0c5f45;
}

.sign-in-register-area .register a:hover {
	background: none;
	border: none;
	color: #15ce05;
}

/* INITIAL POPUP CSS END */

.single-upcomming-match-bet .single-match-result-box {
	border-top: none;
}

.single-upcomming-match-bet .single-match-result-accordion-body .row .col {
	border-right: none;
	border-top: none;
}

.single-upcomming-match-bet .single-match-result-accordion-header {
	font-size: 13px;
	display: flex;
	padding: 0;
	padding-left: 5px;
	min-height: auto;
	text-align: left;
	justify-content: flex-start;
	margin-bottom: 0;
}
.single-upcomming-match-bet {
	background: #fff;
	display: block;
	border-radius: 5px;
	padding: 6px;
	margin-top: 5px;
}

.single-upcomming-match-bet .single-match-result-accordion-body .row {
	margin-left: calc(-0.5 * var(--bs-gutter-x));
	margin-right: calc(-0.5 * var(--bs-gutter-x));
}
.gx-2 {
	margin-left: -8px !important;
	margin-right: -8px !important;
}

.gx-2 > [class^="col-"] {
	padding-left: 8px !important;
	padding-right: 8px !important;
}

.box-card .card-header {
	padding: 8px 10px;
	font-size: 15px;
}

.box-card .card-body {
	background: #d4e8e1;
	padding: 6px 10px;
	padding-bottom: 0;
	font-size: 14px;
	border-radius: 0 0 5px 5px;
}

.card-table table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0px 10px;
}

.card-table table thead th {
	color: #19674b;
	text-transform: uppercase;
	padding: 0px 15px;
	font-size: 12px;
	line-height: 14px;
}
.bg-gradient-navy-blue {
	background: #061237;
	background: -moz-linear-gradient(top, #061237 0%, #264ebf 100%);
	background: -webkit-linear-gradient(top, #061237 0%, #264ebf 100%);
	background: linear-gradient(to bottom, #061237 0%, #264ebf 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#061237', endColorstr='#264ebf',GradientType=0 );
}
.bg-gradient-green-lime {
	background: #052e16;
	background: -moz-linear-gradient(top, #052e16 0%, #09c958 100%);
	background: -webkit-linear-gradient(top, #052e16 0%, #09c958 100%);
	background: linear-gradient(to bottom, #052e16 0%, #09c958 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#052e16', endColorstr='#09c958',GradientType=0 );
}
.bg-gradient-deep-space {
	background: #000000;
	background: -moz-linear-gradient(top, #000000 0%, #434343 100%);
	background: -webkit-linear-gradient(top, #000000 0%, #434343 100%);
	background: linear-gradient(to bottom, #000000 0%, #434343 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#434343',GradientType=0 );
}
.bg-gradient-white-mystic {
	background: #e3e3e3; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to top,
		#e3e3e3,
		#a9a9a9
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to top,
		#e3e3e3,
		#a9a9a9
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.bg-gradient-white-gray {
	background: #fdfbfb; /* fallback for old browsers */
	background: -webkit-linear-gradient(
		to top,
		#ebedee,
		#fdfbfb
	); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to top,
		#ebedee,
		#fdfbfb
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.card-table table tbody tr {
	background: #efefef;
	background: linear-gradient(to bottom, #efefef 0%, #c3c3c3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#efefef", endColorstr="#c3c3c3",GradientType=0 );
	color: #000;
	transform: scale(1, 1);
	-webkit-transform: scale(1, 1);
	-moz-transform: scale(1, 1);
	box-shadow: 0px 6px 40px 10px #e3e3e3;
	border-radius: 8px;
}

.card-table table tbody tr td {
	border-top: 1px solid rgb(126 126 126 / 34%);
	border-bottom: 1px solid rgb(126 126 126 / 34%);
	border-right: 1px solid rgb(126 126 126 / 34%);
	padding: 15px;
}

.card-table table tbody tr td:first-child {
	border-left: 1px solid #dddddd80;
}

.card-table table tbody tr td:last-child {
	border-right: 1px solid #dddddd80;
}

.card-table table tbody tr td:first-child {
	border-top-left-radius: 8px;
}
.card-table table tbody tr td:last-child {
	border-top-right-radius: 8px;
}
.card-table table tbody tr td:first-child {
	border-bottom-left-radius: 8px;
}
.card-table table tbody tr td:last-child {
	border-bottom-right-radius: 8px;
}

#preloader {
	display: none !important;
}

.pagination-row ul li {
	margin: 0 5px;
}

.pagination-row ul li a,
.pagination-row ul li button {
	cursor: pointer;
	padding: 10px 15px;
	display: block;
	border: 1px solid #ddd;
	font-size: 13px;
	font-weight: 500;
	line-height: 14px;
	background: #fff;
	border-radius: 5px;
	height: 36px;
	min-width: 36px;
	border: none;
	box-shadow: 0 0 0 2px transparent;
}

.pagination-row {
	margin-top: 10px !important;
}

.pagination-row ul li a:hover,
.pagination-row ul li button:hover {
	background: #ddd;
}

.pagination-row ul li.active a,
.pagination-row ul li.active button {
	cursor: pointer;
	pointer-events: none;
	background: #19674b;
	border-color: #19674b;
	box-shadow: 0 0 0 2px #0e4833;
	color: #fff;
	font-weight: 700;
}

.mh-70v {
	min-height: 70vh;
}

/* THEME CSS */

.sport-card-level-two-header:hover,
.sport-card-level-two-header.active {
	color: $primary-color-shade-3;
}
.single-event-time:hover {
	color: $primary-color-shade-4;
}

.single-match-result-accordion-header.collapsed {
	border-bottom: 1px solid $primary-color-shade-1;
}

.single-match-result-accordion-body .row .col:last-child,
.single-match-result-accordion-body .row .col-lg-4:nth-child(3n + 3),
.single-match-result-accordion-body .row .col-lg-6:nth-child(2n),
.single-match-result-accordion-body .row .col-lg-3:nth-child(4n + 1) {
	border-right-color: none;
}
.single-match-result-box {
	border-radius: 5px;
	overflow: hidden;
	border: none;
}

.left-sidebar,
.game-area,
.right-sidebar {
	background: transparent;
}

.floating-input.form-control.empty {
	border-color: #ff4545;
}

@-webkit-keyframes inputHighlighter {
	from {
		background: #5264ae;
	}
	to {
		width: 0;
		background: transparent;
	}
}
@-moz-keyframes inputHighlighter {
	from {
		background: #5264ae;
	}
	to {
		width: 0;
		background: transparent;
	}
}
@keyframes inputHighlighter {
	from {
		background: #5264ae;
	}
	to {
		width: 0;
		background: transparent;
	}
}

.admin-body .live-support-icon:hover .icon:before {
	border-right-color: $primary-color-shade-1;
}
.admin-profile-sidebar-option-header-wrapper,
.admin-profile-sidebar-option-header-wrapper:hover {
	color: $fourth-color-shade-1;
}
button.admin-avater-save-btn:hover {
	background: $primary-color-shade-3;
	color: $fourth-color-shade-1;
}

.live-in-play-carousel .owl-nav button:hover,
.live-in-play-carousel .owl-nav button.owl-next:hover {
	background: $primary-color-shade-8 !important;
}

.btn-primary.focus,
button.btn.btn-primary.sign-in-btn:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 248, 38, 0.5);
}

.single-upcomming-match-bet .single-match-result-box {
	background: rgb(34 129 94);
	border-bottom: 1px solid rgba(5, 45, 32, 0.1);
}

.single-upcomming-match-bet .single-match-result-box .match-point,
.single-upcomming-match-bet .single-match-result-box:hover .match-point {
	background: #00000069;
	color: $fourth-color-shade-1 !important;
}
.single-upcomming-match-bet .single-match-result-box .match-name,
.single-upcomming-match-bet .single-match-result-box:hover .match-name {
	color: $fourth-color-shade-1 !important;
}

.cricket-match-carousel-area:before,
.football-match-carousel-area:before {
	background: $primary-color-shade-1;
}

.ngx-select__item.dropdown-item.ngx-select__item_active.active {
	background: $primary-color-shade-3 !important;
}

.banner-slider-carousel .owl-dots button.active {
	background: $secondary-color-shade-1 !important;
}


.user-nav .user-nav-group {
	display: flex;
	flex-flow: column;
}

.user-nav .user-nav-group div.flex {
	display: flex;
}

.user-nav .user-nav-group div.row {
	flex-flow: row nowrap;
}

.user-nav .user-nav-group div.right {
	justify-content: flex-end;
}

.user-nav .user-nav-group input {
	height: 26px;
	background: #22815e;
	outline: none;
	border: 1px solid #135a41;
	font-size: 12px;
	padding-left: 8px;
	color: #fff;
	width: 100%;
	margin-right: 5px;
}

.user-nav .user-nav-group input:-webkit-autofill,
.user-nav .user-nav-group input:-webkit-autofill:hover,
.user-nav .user-nav-group input:-webkit-autofill:focus {
	border: 1px solid #135a41;
	-webkit-text-fill-color: white;
	-webkit-box-shadow: 0 0 0px 1000px #22815e inset;
	-webkit-transition: background-color 5000s ease-in-out 0s;
	transition: background-color 5000s ease-in-out 0s;
}

.user-nav .user-nav-group input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: darken($tertiary-color, 20%);
}
.user-nav .user-nav-group input::-moz-placeholder {
	/* Firefox 19+ */
	color: darken($tertiary-color, 20%);
}
.user-nav .user-nav-group input:-ms-input-placeholder {
	/* IE 10+ */
	color: darken($tertiary-color, 20%);
}
.user-nav .user-nav-group input:-moz-placeholder {
	/* Firefox 18- */
	color: darken($tertiary-color, 20%);
}

.user-nav .user-nav-group .login-btn {
	width: 35px;
	height: 26px;
	background: #face08;
	color: #135a41;
	text-align: center;
	cursor: pointer;
	border: 1px solid #135a41;
	outline: none;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px 8px;
}
.user-nav .user-nav-group div.register-part {
	margin-top: 4px;
}
.user-nav .user-nav-group a {
	font-size: 12px;
	letter-spacing: 0;
	color: #face08;
}

.game-option-list-form .form-control {
	color: #ffffff;
	background-color: #19674b;
	font-size: 14px;
	border: none;
}

.mobile-bottom-navigation {
	position: fixed;
	left: 0;
	bottom: -1px;
	width: 100%;
	z-index: 1001;
	background: #ffffff;
	box-shadow: 0 -3px 5px #00000029;
}

.mobile-bottom-navigation ul {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.mobile-bottom-navigation ul li {
	width: 25%;
}

.mobile-bottom-navigation ul li a,
.mobile-bottom-navigation ul li button {
	background: transparent;
	border: none;
	color: #222;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;
	position: relative;
	padding: 10px;
	font-size: 12px;
	text-align: center;
}

.mobile-bottom-navigation ul li .icon {
	font-size: 18px;
	margin-bottom: 3px;
}

.mobile-bottom-navigation ul li .count {
	color: #19674b;
	font-weight: 700;
}

.footer-area .col-md-2 {
	width: 20%;
}
.footer-area .col-md-3 {
	width: 20%;
}
.footer-area .col-md-4 {
	width: 40%;
}

.mobile-sidebar-area .mobile-sidebar-backdrop {
	z-index: 2001;
	background: #000;
	opacity: 0;
	visibility: hidden;
}
.mobile-sidebar-area.active .mobile-sidebar-backdrop {
	opacity: 0.85;
	visibility: visible;
}

.mobile-sidebar-area .mobile-sidebar-wrapper {
	width: 100%;
	z-index: 2002;
	background: #fff;
	padding: 0;
	transition: 0.3s;
	overflow: hidden;
	overflow-y: auto;
	scrollbar-width: 0px;
}
.mobile-sidebar-area .mobile-sidebar-wrapper::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}
.mobile-sidebar-area:not(.active) .mobile-sidebar-wrapper {
	left: -100% !important;
}
.mobile-sidebar-area .admin-profile-info-area {
	border-radius: 0;
}

.box-card {
	border-color: rgb(255 255 255 / 0%);
	background: #ffffff;
	color: #111;
	box-shadow: 0 0 0 0;
	border-radius: 5px;
	overflow: hidden;
}

.box-card .card-header.bg-dark {
	background: #22815e !important;
	border-bottom: none;
	border-radius: 5px 5px 0 0;
}

.box-card .card-body .border-top {
	border-color: rgb(25 103 75 / 29%) !important;
}

.login-register-page-area {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	z-index: 100000;
	background: #19674b;
	overflow: hidden;
	overflow-y: auto;
	background-size: cover;
	background-position: center right;
}

.login-register-form {
	min-height: 100vh;
	padding: 40px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.login-register-image:before {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #19674b;
	content: "";
	opacity: 0.5;
}
.login-register-image img {
	object-fit: cover;
}
.rs-other-info {
	color: #fff;
}

.login-register-form .rs-form {
	background: #22815e;
	box-shadow: 0 0 18px rgb(25 103 75);
	padding: 20px;
	border-radius: 10px;
}

.login-register-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.login-register-registration-area {
	background: #19674b;
}

.login-register-bg img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.login-register-form .rs-form .form-control {
	background: #22815e;
	border: 1px solid #ddd;
	border-radius: 6px;
	color: #fff;
	font-size: 14px;
}

.login-register-form .rs-form .form-group-input-wrapper {
	margin-bottom: 20px;
}

.login-register-form
	.rs-form
	.form-group-input-wrapper
	.form-group-phone-wrapper
	.phone-code-label {
	border-radius: 6px;
}

.login-register-form .rs-form .form-control option {
	color: #000;
	background-color: #fff;
}

.login-register-form .rs-form .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #fff;
}
.login-register-form .rs-form .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #fff;
}
.login-register-form .rs-form .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #fff;
}
.login-register-form .rs-form .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #fff;
}

.login-register-form .rs-form input:-webkit-autofill,
.login-register-form .rs-form input:-webkit-autofill:hover,
.login-register-form .rs-form input:-webkit-autofill:focus,
.login-register-form .rs-form textarea:-webkit-autofill,
.login-register-form .rs-form textarea:-webkit-autofill:hover,
.login-register-form .rs-form textarea:-webkit-autofill:focus,
.login-register-form .rs-form select:-webkit-autofill,
.login-register-form .rs-form select:-webkit-autofill:hover,
.login-register-form .rs-form select:-webkit-autofill:focus {
	border: 1px solid #fff;
	-webkit-text-fill-color: #fff;
	-webkit-box-shadow: 0 0 0px 1000px #22815e inset;
	transition: background-color 5000s ease-in-out 0s;
}

.login-register-form .rs-form .form-group-input-wrapper .field-icon {
	color: #fff;
}

.login-register-form .logo {
	height: auto;
}

.max-width-400 {
	max-width: 400px;
}

.Toastify__toast-container {
	z-index: 1000000000 !important;
}

.user-nav.dark .dropdown-toggle {
	background: transparent;
	border: none;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-left: auto;
	padding: 0;
}
.user-nav.dark .dropdown-toggle::after {
	display: none;
}
.user-nav.dark .dropdown-toggle:active,
.user-nav.dark .dropdown-toggle:focus {
	box-shadow: 0 0 0 0;
}

.user-nav.dark .user-icon {
	font-size: 28px;
	margin-right: 15px;
	display: flex;
}

.user-nav.dark h3 {
	font-size: 14px;
}

.user-nav.dark p {
	font-size: 12px;
}

.user-nav.dark p .text-blue {
	color: #face08;
}

.user-nav.dark .dropdown-menu {
	background: #104e38;
	padding: 0;
	overflow: hidden;
}

.user-nav.dark .dropdown-menu a {
	font-size: 13px;
}

.user-nav.dark .dropdown-menu .dropdown-divider {
	margin: 2px 0;
}

.user-nav.dark .dropdown-menu button {
	background: #00000040;
	font-size: 14px;
	font-weight: 700;
	padding: 8px;
	transition: 0.3s;
}

.user-nav.dark .dropdown-menu button:hover {
	background: #00000096;
}

.input-validation {
	color: #ff0c0c;
	font-size: 13px;
	margin-top: 2px;
	display: block;
	text-transform: capitalize;
}
.admin-content .tab-pane {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.admin-content .tab-pane .admin-content-scroll-wrapper {
	flex: 1;
}

.home-layout,
.match-details-page-area,
.betslip-card-area,
.upcomming-matches-area {
	background: #fff;
	// box-shadow: 0 0 18px rgb(45 255 136 / 42%);
	border-radius: 5px;
	overflow: hidden;
}

.betslip-card-body-wrapper {
	padding: 0;
	padding-top: 0;
}
.betslip-card-body {
	background: #e9f2ee;
	border-radius: 0;
	padding: 6px;
}

.sport-card-accordion-list {
	background: #fff;
	border-radius: 0 0 5px 5px;
	overflow: hidden;
	// padding: 6px;
	flex: 1;
}

.home-layout {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.home-layout-body {
	background: transparent;
	border-radius: 5px;
	overflow: hidden;
	padding: 0;
	flex: 1;
}

.single-match-bet-item {
	padding: 7px;
	border-radius: 0;
	margin-top: 0;
	background: #fff;
	border-top: 1px solid #d9e5e1;
}

.single-match-bet-item:first-child {
	margin-top: 0;
}
.match-details-page-area {
	padding: 0;
	display: flex;
	flex-direction: column;
}

.match-details-body {
	background: #fff;
	overflow: hidden;
	padding: 6px;
	border-radius: 0 0 5px 5px;
}
.error-page-content.text-center a {
	color: #fff;
	text-decoration: underline;
	font-size: 13px;
}
.error-page-content.text-center a:hover {
	color: $tertiary-color;
}

.error-page-content.text-center p {
	font-size: 20px;
}

.error-page-content.text-center h1 {
	font-size: 50px;
}

.mh-50vh {
	min-height: 50vh;
}

.indicator.up,
.single-match-result-box:hover .indicator.up,
.single-match-result-box:hover .indicator.up * {
	color: #00ff7c !important;
}
.indicator.down,
.single-match-result-box:hover .indicator.down,
.single-match-result-box:hover .indicator.down * {
	color: #ff0000 !important;
}

.single-upcomming-match-bet .single-match-result-accordion-header .status {
	color: #111 !important;
}

.match-details-breadcumb-area {
	background: #22815e;
}
.mobile-sidebar-close {
	width: 40px;
	height: 40px;
	font-size: 18px;
}

.login-register-registration-area .input-validation {
	color: #ffeb3b;
}

.box-card .card-body .bet-info {
	background: #c7e3d9;
	padding-bottom: 5px;
}

.mobile-bottom-navigation ul li.active a {
	color: #19674b;
	font-weight: 700;
}

.sidebar-logout-button {
	background: #face08;
	height: 26px;
	border-radius: 5px;
	font-size: 12px;
	font-weight: 700;
	color: #124c37;
}



.toast-position {
    top: 50% !important;
    transform: translate(-50%, -50%);
}

.single-upcomming-match-bet .single-match-result-accordion-body .col-lg-4 {
    width: 50%;
}

.match-team-list-wrapper {
    padding-left: 20px;
}

.match-team-list-wrapper .match-title-image {
    position: relative;
}

.match-team-list-wrapper:before {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 1px;
    height: 50%;
    background: #19674b;
    content: "";
}

.match-team-list-wrapper .match-title-image:before {
    position: absolute;
    left: -10px;
    top: 9px;
    width: 12px;
    height: 1px;
    background: #19674b;
    content: "";
}

.loading-spinner-area.overlay {
    z-index: 1000;
    background: rgb(255 255 255 / 75%) !important;
}

.message-box-content .icon {
    font-size: 110px;
    color: #5CB85C;
    display: inline-flex;
    margin-bottom: 20px;
}

.message-box-content h3 {
    font-size: 25px;
    color: #999;
    margin-bottom: 5px;
    font-weight: 600;
}

.message-box-content .text {
    font-size: 22px;
    color: #777;
}

.message-box-content .icon img {
    height: 130px;
}

.message-box-content .icon {
    margin-bottom: 40px;
}