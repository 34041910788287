@font-face {
    font-family: 'ProximaNovaRegular';
    src: url('ProximaNova-Bold.eot');
    src: url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Bold.woff2') format('woff2'),
        url('ProximaNova-Bold.woff') format('woff'),
        url('ProximaNova-Bold.ttf') format('truetype'),
        url('ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNovaRegular';
    src: url('ProximaNova-Regular.eot');
    src: url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
        url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff'),
        url('ProximaNova-Regular.ttf') format('truetype'),
        url('ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNovaCondensedRegular';
    src: url('ProximaNovaCond-Regular.eot');
    src: url('ProximaNovaCond-Regular.eot?#iefix') format('embedded-opentype'),
        url('ProximaNovaCond-Regular.woff2') format('woff2'),
        url('ProximaNovaCond-Regular.woff') format('woff'),
        url('ProximaNovaCond-Regular.ttf') format('truetype'),
        url('ProximaNovaCond-Regular.svg#ProximaNovaCond-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNovaCondensedRegular';
    src: url('ProximaNovaCond-Semibold.eot');
    src: url('ProximaNovaCond-Semibold.eot?#iefix') format('embedded-opentype'),
        url('ProximaNovaCond-Semibold.woff2') format('woff2'),
        url('ProximaNovaCond-Semibold.woff') format('woff'),
        url('ProximaNovaCond-Semibold.ttf') format('truetype'),
        url('ProximaNovaCond-Semibold.svg#ProximaNovaCond-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

